import React from "react";
import { observer } from "mobx-react";

import { ReactComponent as PlusSVG } from "./../../assets/plus-small.svg";
import { ReactComponent as MinusSVG } from "./../../assets/minus-small.svg";

import WindowPopup from "./WindowPopup";
import Dropdown from "../elements/Dropdown";
import ToolButton from "../elements/ToolButton";
import { useIntl } from "react-intl";
import useStores from "../../hooks/useStores";

const clone = (object) => JSON.parse(JSON.stringify(object));

const toString = (v, delimiter) =>
  v == null ? "" : ("" + v).replace(".", delimiter);
const toNumber = (v, delimiter) =>
  typeof v === "string" ? +v.replace(delimiter, ".") : v;
const isNumber = (v, delimiter) =>
  !isNaN(typeof v === "string" ? +v.replace(delimiter, ".") : v);

const PrecipitationConfigPopup = ({
  allSprinklers,
  close,
  showPrecipitationPopup,
  saveSettings,
  delimiter = ",",
}) => {
  const { formatMessage } = useIntl();

  const sprinklers = React.useMemo(
    () => allSprinklers.filter(({ precipitation }) => precipitation != null),
    [allSprinklers]
  );

  // contains a clone of selected sprinkler
  const [selectedSprinkler, changeSelectedSprinkler] = React.useState(
    clone(sprinklers[0])
  );

  const applySelectedSprinkler = React.useCallback(() => {
    if (selectedSprinkler != null) {
      // convert to number
      selectedSprinkler.precipitation = selectedSprinkler.precipitation.map(
        (v) => toNumber(v, delimiter)
      );

      const sprinkler = allSprinklers.find(
        ({ name }) => name === selectedSprinkler.name
      );
      let shouldUpdate =
        sprinkler.precipitation.length ===
        selectedSprinkler.precipitation.length;

      if (!shouldUpdate) {
        shouldUpdate = sprinkler.precipitation.some(
          (v, i) => selectedSprinkler.precipitation[i] !== v
        );
      }

      if (shouldUpdate) {
        sprinkler.precipitation = selectedSprinkler.precipitation;
      }

      return shouldUpdate;
    }
  }, [allSprinklers, delimiter, selectedSprinkler]);

  const onDropdownChange = React.useCallback(
    (v) => {
      const sprinkler = sprinklers.find(({ name }) => name === v);
      if (sprinkler == null) return;
      changeSelectedSprinkler(clone(sprinkler));
    },
    [sprinklers]
  );

  // collect dropdown options list
  const dropdownData = React.useMemo(() => {
    return sprinklers
      .map(({ name }) => ({
        value: name,
        label: formatMessage({ id: name }),
      }))
      .sort((a, b) => (a.name > b.name ? 1 : -1));
  }, [sprinklers, formatMessage]);

  return (
    <WindowPopup
      className="precipitation-config-popup"
      close={close}
      style={{ minHeight: 300 }}
      footer={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <button
            onClick={() => {
              if (applySelectedSprinkler()) {
                showPrecipitationPopup();
              }
              close();
            }}
          >
            Apply
          </button>
          <button
            onClick={async () => {
              try {
                close();
                const sprinklerUpdated = applySelectedSprinkler();
                await saveSettings();
                if (sprinklerUpdated) {
                  showPrecipitationPopup();
                }
              } catch (e) {
                window.alert("Save settings failed");
              }
            }}
          >
            Save
          </button>
        </div>
      }
      title={
        <Dropdown
          data={dropdownData}
          current={selectedSprinkler ? selectedSprinkler.name : undefined}
          onChange={onDropdownChange}
        />
      }
    >
      {selectedSprinkler != null && (
        <>
          <div
            className="scroll"
            style={{
              padding: "5px 10px 5px 5px",
              height: 300,
              overflow: "auto",
            }}
          >
            <div
              className="precipitation-config-grid"
              style={{
                display: "grid",
                gridTemplateColumns: "50px 5px 60px 1fr 30px 30px",
                gridGap: "5px 10px",
                alignItems: "center",
              }}
            >
              {selectedSprinkler.precipitation.map((value, idx) => (
                <React.Fragment key={idx}>
                  <span style={{ textAlign: "left" }}>{idx} Foot</span>
                  <span>/</span>
                  <span style={{ textAlign: "left" }}>
                    {toString(Number(idx * 0.3048).toFixed(2), delimiter)} m
                  </span>
                  <input
                    value={toString(value, delimiter)}
                    onChange={(e) => {
                      const { value: v } = e.target;
                      if (!isNumber(v, delimiter)) {
                        e.target.value = value;
                      } else {
                        const sprinkler = clone(selectedSprinkler);
                        sprinkler.precipitation.splice(idx, 1, v);
                        changeSelectedSprinkler(sprinkler);
                      }
                    }}
                  />
                  <ToolButton
                    onClick={() => {
                      const sprinkler = clone(selectedSprinkler);
                      sprinkler.precipitation.splice(idx, 1);
                      changeSelectedSprinkler(sprinkler);
                    }}
                    className="setting-control-item"
                  >
                    <MinusSVG />
                  </ToolButton>
                  <ToolButton
                    onClick={() => {
                      const sprinkler = clone(selectedSprinkler);
                      sprinkler.precipitation.splice(idx + 1, 0, value);
                      changeSelectedSprinkler(sprinkler);
                    }}
                    className="setting-control-item"
                  >
                    <PlusSVG />
                  </ToolButton>
                </React.Fragment>
              ))}
            </div>
          </div>
        </>
      )}
    </WindowPopup>
  );
};

let PrecipitationConfigPopupWithState = () => {
  const { uiState } = useStores();
  const { showPrecipitationConfigPopup } = uiState;
  if (!showPrecipitationConfigPopup) return null;

  try {
    const { elements } = uiState.settingsState;
    const sprinklers = [...elements.mpRotator, ...elements.mpStrip];

    return (
      <PrecipitationConfigPopup
        allSprinklers={sprinklers}
        close={uiState.togglePrecipitationConfigPopup}
        showPrecipitationPopup={
          uiState.plan != null
            ? async () => {
                await uiState.plan.cleanupSprinklerCoverage();
                uiState.plan.fillSprinklerCoverage();
              }
            : undefined
        }
        saveSettings={uiState.saveSettings}
      />
    );
  } catch (e) {
    return null;
  }
};

PrecipitationConfigPopupWithState = observer(PrecipitationConfigPopupWithState);
export default PrecipitationConfigPopupWithState;
