import React from "react";
import Tooltip from "../tooltip/Tooltip";
import ToolButton from "../elements/ToolButton";

function ToolsItem({
  isActive,
  onClick = () => {},
  disabled = false,
  icon,
  hasLine = false,
  tooltip = null,
}) {
  return (
    <React.Fragment>
      <ToolButton
        className={`tools-item ${hasLine ? "tools-no_margin" : ""} ${
          isActive ? "active" : ""
        } ${disabled ? "disabled" : ""}`}
        disabled={disabled}
      >
        <Tooltip position="right" tooltip={tooltip}>
          <i onClick={!disabled ? onClick : undefined}>{icon}</i>
        </Tooltip>
      </ToolButton>

      {hasLine ? <div className="tools-line"></div> : ""}
    </React.Fragment>
  );
}

export default ToolsItem;
