import { priceFormat } from "@dvsproj/ipat-core/formatter";

/**
 * Get all bom items
 * @param {*} settingsState settings.json
 */
export const getAllArticles = (settingsState) => {
  const { bomList, boxes } = settingsState;
  const dictionary = {};

  Object.entries(bomList).forEach(([bomId, list]) => {
    list.forEach((e) => {
      const articleNO = normalizeArticleNO(e.articleNO);

      dictionary[articleNO] = {
        ...e,
        bomId,
        qualityArticles: list.map((el) => normalizeArticleNO(el.articleNO)),
      };
    });
  });

  boxes.forEach((b) => {
    const boxArticleNO = normalizeArticleNO(b.articleNO);
    dictionary[boxArticleNO] = b;
  });

  return dictionary;
};

/**
 *
 * @param {*} settingsState
 */
export const getAllBomList = (settingsState) => {
  const { bomList } = settingsState;

  let result = [];
  for (const key in bomList) {
    result.push({
      bomId: key,
      qualityList: bomList[key].map((e) => ({
        ...e,
        articleNO: normalizeArticleNO(e.articleNO),
      })),
      quantity: 0,
    });
  }
  return result;
};

export const normalizeArticleNO = (articleNO) => {
  return articleNO.toUpperCase();
};

/**
 *
 * @param {*} bomItems
 */
export const groupBomByBomId = (bomItems) => {
  let calculateItems = [];

  for (const item of bomItems) {
    let curIdx = calculateItems.findIndex(({ bomId }) => bomId === item.bomId);

    if (curIdx >= 0) {
      calculateItems[curIdx] = {
        ...calculateItems[curIdx],
        ...item,
        quantity: calculateItems[curIdx].quantity + item.quantity,
      };
    } else {
      calculateItems.push(item);
    }
  }

  return calculateItems;
};

/**
 * Get grouped bom items
 * @param {*} planBomItems array of bomItemFactory
 * @param {*} settingsState settings.json
 */
export const groupBomByBomOrder = (planBomItems, settingsState) => {
  const { bomOrder } = settingsState;

  // bom grouping and sorting
  let bomItems = [];

  let bomOrderExist = [];
  if (settingsState) {
    for (const { id, order, label } of bomOrder) {
      bomOrderExist = bomOrderExist.concat(order);
      bomItems.push({
        id,
        label,
        order,
        childs: order
          .map((bomId) => planBomItems.find((bom) => bom.bomId === bomId))
          .filter((e) => e != null),
      });
    }
  }

  // if groupKey not exist in bomOrder
  const otherBoms = planBomItems.filter(
    ({ bomId }) => bomOrderExist.indexOf(bomId) === -1
  );
  if (otherBoms.length > 0) {
    bomItems.push({
      key: "other",
      label: "bomOrder.others",
      childs: otherBoms,
    });
  }

  return bomItems;
};

/**
 *
 * @param {*} bomItems
 */
export const normalizeBomItems = (bomItems, settingsState) => {
  const allArticles = getAllArticles(settingsState);

  const newBomItems = bomItems
    .filter(
      ({ defaultQuantity, quantity }) => quantity > 0 || defaultQuantity > 0
    )
    .map(
      ({
        bomId,
        pricePerUnit,
        defaultQuantity,
        quantity,
        name,
        articleNO,
        description,
        productLink,
        ...item
      }) => {
        // IPAT-532 (IPAT-281) set default for plans with old articles
        const defaultQualityElement = {
          bomId,
          articleNO,
          type: "expert",
          name,
          description,
          productLink,
        };

        let res = {
          bomId,
          pricePerUnit,
          defaultQuantity,
          quantity,
          name,
          articleNO,
          description,
          productLink,
          qualityList: [defaultQualityElement],
        };

        const settings = allArticles[normalizeArticleNO(articleNO)];
        if (settings != null) {
          const { qualityArticles, ...settingsData } = settings;

          res = {
            ...res,
            ...settingsData,
            bomId: res.bomId ?? settings.bomId,
            qualityList: (qualityArticles ?? []).map((a) => {
              const { qualityArticles, ...data } = allArticles[a];
              return data;
            }),
          };
        }

        // for old plans
        if (item.bom != null) {
          const currentQuality = item.bom[item.quality];
          res = {
            ...res,
            articleNO: res.articleNO ?? currentQuality.articleNO,
            name: res.name ?? currentQuality.name,
            description: res.description ?? currentQuality.description,
          };
        }

        return res;
      }
    );

  return newBomItems;
};

export const findBoxesWithProduct = (settingsState, product) => {
  const { boxes } = settingsState;

  const productBoxes = boxes
    .filter(
      (b) =>
        b.childArticleNO === product.articleNO &&
        b.childCount <= product.quantity
    )
    .sort((a, b) => a.childCount - b.childCount);

  if (productBoxes.length === 0) return [product];

  const box = productBoxes[productBoxes.length - 1];
  const boxQuantity = Math.floor(product.quantity / box.childCount);

  return [
    {
      bomId: box.articleNO,
      articleNO: box.articleNO,
      qualityList: [
        {
          type: product.quality,
          ...box,
        },
      ],
      quantity: boxQuantity,
    },
    ...findBoxesWithProduct(settingsState, {
      bomId: product.bomId,
      articleNO: product.articleNO,
      qualityList: product.qualityList,
      quantity: product.quantity - boxQuantity * box.childCount,
    }),
  ];
};

export const convertBomListByBoxes = (settingsState, products) => {
  const result = [];

  for (const item of products) {
    result.push(...findBoxesWithProduct(settingsState, item));
  }

  return result;
};

/**
 *
 * @param {*} bomItems
 * @returns
 */
export const calculateTotalPrice = (bomItems) => {
  let totalPrice = 0;
  for (const { price } of bomItems) {
    totalPrice += +priceFormat(null, price);
  }

  return totalPrice;
};

/**
 *
 * @param {*} bomItems array of bomItemFactory
 * @param {*} settingsState settings.json
 */
export const bomHasOutdatedElements = (bomItems, settingsState) => {
  const allArticles = getAllArticles(settingsState);

  let result = false;
  for (const bomItem of bomItems) {
    if (allArticles[bomItem.articleNO] == null) {
      result = true;
      break;
    }
  }

  return result;
};

/**
 *
 * @param {*} bomItems array of bomItemFactory
 * @param {*} settingsState settings.json
 */
export const bomHasDeprecatedElements = (bomItems, settingState) => {
  const { deprecated } = settingState;
  return (
    bomItems.length > 0 &&
    bomItems.findIndex((bi) => deprecated.includes(bi.articleNO)) > -1
  );
};
