import React from "react";
import { observer } from "mobx-react";

import { ReactComponent as SelectSVG } from "./../../assets/select.svg";
import { ReactComponent as RuleSVG } from "./../../assets/rule.svg";
import { ReactComponent as UndoSVG } from "./../../assets/undo.svg";
import { ReactComponent as RedoSVG } from "./../../assets/redo.svg";

import Popup from "../popups/Popup";
import ShapeTools from "./ShapeTools";
import ToolsItem from "./ToolsItem";
import SprinklerTools from "./SprinklerTools";
import SystemElementTools from "./SystemElementTools";
import PipelineTools from "./PipelineTools";
import SensorTools from "./SensorTools";
import Scroll from "../elements/Scroll";
import { useIntl } from "react-intl";
import useStores from "../../hooks/useStores";

function ToolsPopup({ text }) {
  const [hasActive, changeActive] = React.useState(true);
  if (!hasActive) return null;

  return (
    <div className="popup-item">
      <Popup
        className="start-draw"
        hasActive={hasActive}
        position="right"
        hasCloseButton={false}
        onClose={() => {
          changeActive(false);
        }}
      >
        <div className="text-popup">{text}</div>
      </Popup>
    </div>
  );
}

function Tools({
  step,
  selectedTool,
  setSelectedTool,
  hasPopup,
  undo,
  redo,
  scale,
  labels,
  planIsEditable,
}) {
  const parentRef = React.useRef(null);
  const { formatMessage } = useIntl();

  React.useEffect(() => {
    if (document?.activeElement?.disabled) {
      parentRef?.current?.focus();
    }
  });

  var ToolsElement;
  if (scale != null) {
    switch (step.name) {
      case "areas":
        ToolsElement = <ShapeTools />;
        break;
      case "sprinklers":
        ToolsElement = <SprinklerTools />;
        break;
      case "system-elements":
        ToolsElement = <SystemElementTools />;
        break;
      case "pipeline":
        ToolsElement = <PipelineTools />;
        break;
      case "sensor":
        ToolsElement = <SensorTools />;
        break;
      default:
        ToolsElement = null;
        break;
    }
  }

  if (ToolsElement == null) return null;

  return (
    <div className={`tools ${step.name}`}>
      {hasPopup ? (
        <ToolsPopup text={formatMessage({ id: labels.startToDraw })} />
      ) : null}
      <Scroll className="tools-wrap">
        <div className="tools-content" ref={parentRef} tabIndex="0">
          <ToolsItem
            icon={<SelectSVG />}
            type="select"
            isActive={selectedTool === "select"}
            onClick={() => {
              setSelectedTool("select");
            }}
            tooltip={
              labels ? formatMessage({ id: labels.select.tooltip }) : null
            }
          />

          {planIsEditable ? ToolsElement : null}

          <ToolsItem
            icon={<RuleSVG />}
            hasLine={true}
            type="ruler"
            isActive={selectedTool === "ruler"}
            onClick={() => {
              setSelectedTool("ruler");
            }}
            tooltip={
              labels ? formatMessage({ id: labels.ruler.tooltip }) : null
            }
          />
          <ToolsItem
            icon={<UndoSVG />}
            type="undo"
            isActive={false}
            disabled={undo == null || !planIsEditable}
            onClick={undo ? undo : () => {}}
            tooltip={labels ? formatMessage({ id: labels.undo.tooltip }) : null}
          />
          <ToolsItem
            icon={<RedoSVG />}
            type="redo"
            isActive={false}
            disabled={redo == null || !planIsEditable}
            onClick={redo ? redo : () => {}}
            tooltip={labels ? formatMessage({ id: labels.redo.tooltip }) : null}
          />
        </div>
      </Scroll>
    </div>
  );
}

let ToolsWithState = () => {
  const { uiState } = useStores();
  return (
    <Tools
      selectedTool={uiState.selectedTool}
      setSelectedTool={uiState.setSelectedTool}
      step={uiState.step}
      hasPopup={
        uiState.plan
          ? uiState.step.name === "areas" &&
            uiState.plan.areas.length === 0 &&
            ((uiState.plan.opacity != null && uiState.selectedTool == null) ||
              uiState.plan.background == null)
          : false
      }
      undo={uiState.undo}
      redo={uiState.redo}
      scale={uiState.plan ? uiState.plan.scale : undefined}
      labels={uiState.settingsState ? uiState.settingsState.texts.tools : null}
      planIsEditable={uiState.planIsEditable}
    />
  );
};

ToolsWithState = observer(ToolsWithState);

export default ToolsWithState;
