import React from "react";
import { observer } from "mobx-react";

import Properties from "./Properties";
import Dropdown from "../elements/Dropdown";
import Toggle from "../elements/Toggle";
import Info from "../elements/Info";

import { ReactComponent as AirCompressorSVG } from "./../../assets/air-compressor.svg";
import { ReactComponent as ControllerSVG } from "./../../assets/controller.svg";
import { ReactComponent as FertilizerSVG } from "./../../assets/fertilizer.svg";
import { ReactComponent as WaterSupplySVG } from "./../../assets/water-supply.svg";
import { ReactComponent as WaterFilterSVG } from "./../../assets/water-filter.svg";
import { ReactComponent as WaterMeterSVG } from "./../../assets/water-meter.svg";
import { ReactComponent as WaterTapSVG } from "./../../assets/water-tap.svg";
import { ReactComponent as ValveBoxSVG } from "./../../assets/valve-box.svg";
import { ReactComponent as VideoSVG } from "./../../assets/video.svg";
import { ReactComponent as BigWaterSupplySVG } from "./../../assets/water-supply-big-icon.svg";
import { ReactComponent as CombiBoxSVG } from "./../../assets/combi-box-big.svg";

import NumberInput from "../inputs/NumberInput";
import { urlDecorator } from "../../utils/api";
import { useIntl, injectIntl } from "react-intl";
import Checkbox from "../elements/Checkbox";
import useStores from "../../hooks/useStores";

function PropertiesItem({ hasWrap = false, children }) {
  return (
    <div className={`system-properties-item ${hasWrap ? "item-wrap" : ""}`}>
      {children}
    </div>
  );
}

const RecomItem = ({ name, value, changeValue, onChange, disabled }) => {
  const { formatMessage } = useIntl();
  return (
    <PropertiesItem>
      <div>{formatMessage({ id: name })}</div>
      <Toggle
        current={value}
        onChange={(val) => {
          changeValue(val);
          onChange();
        }}
        disabled={disabled}
      />
    </PropertiesItem>
  );
};

const RecomItems = observer(({ recom, onChange, disabled }) => {
  return (
    <React.Fragment>
      {Object.values(recom).map((item, key) => {
        return (
          <RecomItem
            key={`recom-${key}`}
            {...item}
            onChange={onChange}
            disabled={disabled}
          />
        );
      })}
    </React.Fragment>
  );
});

const WaterSupplyProperties = injectIntl(
  class extends React.Component {
    componentDidMount() {
      const { showWaterSupplyVideo, waterSupplyDialog } = this.props;

      if (showWaterSupplyVideo == null) {
        waterSupplyDialog();
      }
    }
    render() {
      const {
        quality,
        qualityData,
        recom,
        changeQuality,
        quantity,
        changeQuantity,
        disabled,
        onChange,
        onChangeDebounced,
        setShowWaterSupplyVideo = () => {},
        hasWaterSupplyVideoLink,
        showWaterVolumeError = () => {},
        labels,
        intl: { formatMessage },
      } = this.props;

      return (
        <React.Fragment>
          <PropertiesItem hasWrap={true}>
            <div>{labels ? formatMessage({ id: labels.quality }) : null}</div>
            <Dropdown
              current={quality}
              data={qualityData.map((e) => ({
                ...e,
                label: formatMessage({ id: e.label }),
              }))}
              onChange={(val) => {
                changeQuality(val);
                onChange();
              }}
              disabled={disabled}
            />
          </PropertiesItem>
          {<RecomItems recom={recom} onChange={onChange} disabled={disabled} />}
          <PropertiesItem>
            <div className="water-volume">
              <span>
                {labels ? formatMessage({ id: labels.waterVolume }) : null}
              </span>
              <NumberInput
                type="text"
                value={quantity}
                precision={2}
                onChange={(val) => {
                  changeQuantity(val);
                  onChangeDebounced();
                  showWaterVolumeError(val);
                }}
                disabled={disabled}
              />
              <span>m&sup3;/h</span>
            </div>
            <Info
              url={
                labels
                  ? urlDecorator(
                      formatMessage({ id: labels.waterVolumeInfoURL })
                    )
                  : null
              }
            />
          </PropertiesItem>
          {hasWaterSupplyVideoLink && (
            <PropertiesItem>
              <div
                className="video-info"
                onClick={() => {
                  setShowWaterSupplyVideo(true);
                }}
              >
                <div>
                  <VideoSVG />
                </div>
                <div className="video-text">
                  {formatMessage({ id: labels.video })}
                </div>
              </div>
            </PropertiesItem>
          )}
        </React.Fragment>
      );
    }
  }
);

function ControllerProperties({
  type,
  recom,
  changeType,
  typeData,
  disabled,
  onChange,
  labels,
}) {
  const { formatMessage } = useIntl();

  return (
    <React.Fragment>
      <PropertiesItem hasWrap={true}>
        <div>{labels ? formatMessage({ id: labels.type }) : null}</div>
        <Dropdown
          current={type}
          data={typeData.map((e) => ({
            ...e,
            label: formatMessage({ id: e.label }),
          }))}
          onChange={(val) => {
            changeType(val);
            onChange();
          }}
          disabled={disabled}
        />
      </PropertiesItem>
    </React.Fragment>
  );
}

function ValveBoxProperties({
  type,
  recom,
  changeType,
  typeData,
  disabled,
  onChange,
  labels,
}) {
  const { formatMessage } = useIntl();

  return (
    <React.Fragment>
      <PropertiesItem hasWrap={true}>
        <div>{labels ? formatMessage({ id: labels.type }) : null}</div>
        <Dropdown
          current={type}
          data={typeData.map((e) => ({
            ...e,
            label: formatMessage({ id: e.label }),
          }))}
          onChange={(val) => {
            changeType(val);
            onChange();
          }}
          disabled={disabled}
        />
      </PropertiesItem>
      {<RecomItems recom={recom} onChange={onChange} disabled={disabled} />}
    </React.Fragment>
  );
}

function WaterTapPointProperties() {
  return (
    <React.Fragment>
      <PropertiesItem hasWrap={true}></PropertiesItem>
    </React.Fragment>
  );
}

function FertilizerProperties({ labels, value, onChange }) {
  const { formatMessage } = useIntl();

  return (
    <React.Fragment>
      <PropertiesItem hasWrap={false}>
        <div>{formatMessage({ id: labels.type })}</div>
        <Checkbox value={value} onChange={onChange} reversed={true} />
      </PropertiesItem>
    </React.Fragment>
  );
}

const WaterFilterProperties = ({
  disabled,
  labels,
  filter,
  filterData,
  sensor,
  changeSensor,
  changeFilter,
  onChange,
}) => {
  const { formatMessage } = useIntl();

  return (
    <React.Fragment>
      <PropertiesItem hasWrap={true}>
        <div>{labels ? formatMessage({ id: labels.filter }) : null}</div>
        <Dropdown
          current={filter}
          data={filterData.map((e) => ({
            ...e,
            label: formatMessage({ id: e.label }),
          }))}
          onChange={(val) => {
            changeFilter(val);
            onChange();
          }}
          disabled={disabled}
        />
      </PropertiesItem>
      <PropertiesItem hasWrap={false}>
        <div>{formatMessage({ id: labels.sensor })}</div>
        <Checkbox
          value={sensor === "sensor"}
          onChange={(val) => {
            changeSensor(val);
            onChange();
          }}
          reversed={true}
        />
      </PropertiesItem>
    </React.Fragment>
  );
};

const WaterMeterProperties = ({
  disabled,
  labels,
  meter,
  meterData,
  changeMeter,
  onChange,
}) => {
  const { formatMessage } = useIntl();

  return (
    <React.Fragment>
      <PropertiesItem hasWrap={true}>
        <div>{labels ? formatMessage({ id: labels.placement }) : null}</div>
        <Dropdown
          current={meter}
          data={meterData.map((e) => ({
            ...e,
            label: formatMessage({ id: e.label }),
          }))}
          onChange={(val) => {
            changeMeter(val);
            onChange();
          }}
          disabled={disabled}
        />
      </PropertiesItem>
    </React.Fragment>
  );
};

const CombiBoxProperties = ({
  disabled,
  labels,
  combiBoxType,
  combiBoxData,
  sensor,
  changeSensor,
  changeCombiBoxType,
  onChange,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <PropertiesItem hasWrap={true}>
        <div>{labels ? formatMessage({ id: labels.placement }) : null}</div>
        <Dropdown
          current={combiBoxType}
          data={combiBoxData.map((e) => ({
            ...e,
            label: formatMessage({ id: e.label }),
          }))}
          onChange={(val) => {
            changeCombiBoxType(val);
            onChange();
          }}
          disabled={disabled}
        />
      </PropertiesItem>
      <PropertiesItem hasWrap={false}>
        <div>{formatMessage({ id: labels.sensor })}</div>
        <Checkbox
          value={sensor === "sensor"}
          onChange={(val) => {
            changeSensor(val);
            onChange();
          }}
          reversed={true}
        />
      </PropertiesItem>
    </>
  );
};

function SystemElementProperties({
  systemType,
  waterQuality,
  recom,
  waterQualityData,
  сhangeWaterQuality,
  waterQuantity,
  changeWaterQuantity,
  waterFilter,
  waterFilterData,
  changeWaterFilter,
  pressureSensor,
  changePressureSensor,
  controllerType,
  controllerTypeData,
  changeControllerType,
  valveBoxType,
  valveBoxTypeData,
  changeValveBoxType,
  fertilizerType,
  changeFertilizerType,
  waterMeter,
  waterMeterData,
  changeWaterMeterType,
  combiBoxType,
  changeCombiBoxType,
  combiBoxData,
  onDisable,
  disabled,
  onRemove,
  onChange,
  onChangeDebounced,
  setShowWaterSupplyVideo = () => {},
  showWaterSupplyVideo,
  waterSupplyDialog,
  hasWaterSupplyVideoLink,
  showWaterVolumeError = () => {},
  labels,
}) {
  const { formatMessage } = useIntl();

  let Element;
  let header;
  switch (systemType) {
    case "water-supply":
      Element = (
        <WaterSupplyProperties
          quality={waterQuality}
          recom={recom}
          qualityData={waterQualityData}
          changeQuality={сhangeWaterQuality}
          quantity={waterQuantity}
          changeQuantity={changeWaterQuantity}
          disabled={disabled}
          onChange={onChange}
          onChangeDebounced={onChangeDebounced}
          setShowWaterSupplyVideo={setShowWaterSupplyVideo}
          hasWaterSupplyVideoLink={hasWaterSupplyVideoLink}
          labels={labels ? labels["waterSupply"] : null}
          showWaterSupplyVideo={showWaterSupplyVideo}
          waterSupplyDialog={waterSupplyDialog}
          showWaterVolumeError={showWaterVolumeError}
        />
      );
      header = {
        icon: <WaterSupplySVG />,
        title: labels
          ? formatMessage({ id: labels["waterSupply"].title })
          : null,
      };
      break;
    case "controller":
      Element = (
        <ControllerProperties
          type={controllerType}
          recom={recom}
          typeData={controllerTypeData}
          changeType={changeControllerType}
          disabled={disabled}
          onChange={onChange}
          labels={labels ? labels["controller"] : null}
        />
      );
      header = {
        icon: <ControllerSVG />,
        title: labels
          ? formatMessage({ id: labels["controller"].title })
          : null,
      };

      break;
    case "valve-box":
      Element = (
        <ValveBoxProperties
          type={valveBoxType}
          recom={recom}
          typeData={valveBoxTypeData}
          changeType={changeValveBoxType}
          disabled={disabled}
          onChange={onChange}
          labels={labels ? labels["valveBox"] : null}
        />
      );
      header = {
        icon: <ValveBoxSVG />,
        title: labels ? formatMessage({ id: labels["valveBox"].title }) : null,
      };
      break;
    case "water-tap-point":
      Element = (
        <WaterTapPointProperties
          disabled={disabled}
          labels={labels ? labels["water-tap-point"] : null}
        />
      );
      header = {
        icon: <WaterTapSVG />,
        title: labels
          ? formatMessage({ id: labels["water-tap-point"].title })
          : null,
      };
      break;
    case "fertilizer":
      Element = (
        <FertilizerProperties
          disabled={disabled}
          labels={labels ? labels["fertilizer"] : null}
          value={fertilizerType === "sensor"}
          onChange={(value) => {
            changeFertilizerType(value ? "sensor" : "default");
            onChange();
          }}
        />
      );
      header = {
        icon: <FertilizerSVG />,
        title: labels
          ? formatMessage({ id: labels["fertilizer"].title })
          : null,
      };
      break;
    case "air-compressor":
      Element = null;
      header = {
        icon: <AirCompressorSVG />,
        title: labels
          ? formatMessage({ id: labels.airCompressor.title })
          : null,
      };
      break;
    case "water-filter":
      Element = (
        <WaterFilterProperties
          disabled={disabled}
          labels={labels ? labels["waterFilter"] : null}
          filter={waterFilter}
          filterData={waterFilterData}
          changeFilter={changeWaterFilter}
          sensor={pressureSensor}
          changeSensor={changePressureSensor}
          onChange={onChange}
        />
      );
      header = {
        icon: <WaterFilterSVG />,
        title: labels ? formatMessage({ id: labels.waterFilter.title }) : null,
      };
      break;
    case "water-meter":
      Element = (
        <WaterMeterProperties
          disabled={disabled}
          labels={labels ? labels["waterMeter"] : null}
          meter={waterMeter}
          meterData={waterMeterData}
          changeMeter={changeWaterMeterType}
          onChange={onChange}
        />
      );
      header = {
        icon: <WaterMeterSVG />,
        title: labels ? formatMessage({ id: labels.waterMeter.title }) : null,
      };
      break;
    case "combi-box":
      Element = (
        <CombiBoxProperties
          disabled={disabled}
          labels={labels ? labels["combiBox"] : null}
          pressureSensor
          combiBoxType={combiBoxType}
          combiBoxData={combiBoxData}
          changeCombiBoxType={changeCombiBoxType}
          sensor={pressureSensor}
          changeSensor={changePressureSensor}
          onChange={onChange}
        />
      );
      header = {
        icon: <CombiBoxSVG />,
        title: labels ? formatMessage({ id: labels.combiBox.title }) : null,
      };
      break;
    default:
      break;
  }

  return (
    <Properties
      onDisable={onDisable}
      disabled={disabled}
      onRemove={onRemove}
      onChange={onChange}
      header={header}
    >
      {Element}
    </Properties>
  );
}

let SystemElementPropertiesWithState = () => {
  const { uiState } = useStores();
  const { formatMessage } = useIntl();

  const { selectedElement } = uiState;

  const hasWaterSupplyVideoLink =
    uiState.settingsState &&
    uiState.settingsState.texts.steps.systemElements.waterSupplyDialog
      .videoLink != null &&
    uiState.settingsState.texts.steps.systemElements.waterSupplyDialog
      .videoLink !== ""
      ? true
      : false;

  return (
    <SystemElementProperties
      systemType={selectedElement.systemType}
      waterQuality={selectedElement.waterQuality}
      recom={selectedElement.recom ? selectedElement.recom : []}
      waterQualityData={selectedElement.waterQualityData}
      сhangeWaterQuality={selectedElement.сhangeWaterQuality}
      waterQuantity={selectedElement.waterQuantity}
      changeWaterQuantity={selectedElement.changeWaterQuantity}
      waterFilter={selectedElement.waterFilter}
      waterFilterData={selectedElement.waterFilterData}
      changeWaterFilter={selectedElement.changeWaterFilter}
      pressureSensor={selectedElement.waterPressureSensor}
      changePressureSensor={selectedElement.changeWaterPressureSensor}
      controllerType={selectedElement.controllerType}
      controllerTypeData={selectedElement.controllerTypeData}
      changeControllerType={selectedElement.changeControllerType}
      valveBoxType={selectedElement.valveBoxType}
      valveBoxTypeData={selectedElement.valveBoxTypeData}
      changeValveBoxType={selectedElement.changeValveBoxType}
      fertilizerType={selectedElement.fertilizerType}
      changeFertilizerType={selectedElement.changeFertilizerType}
      waterMeter={selectedElement.waterMeterType}
      waterMeterData={selectedElement.waterMeterData}
      changeWaterMeterType={selectedElement.changeWaterMeterType}
      combiBoxType={selectedElement.combiBoxType}
      changeCombiBoxType={selectedElement.changeCombiBoxType}
      combiBoxData={selectedElement.combiBoxData}
      onDisable={selectedElement.onDisable}
      disabled={selectedElement.disabled || !uiState.planIsEditable}
      onRemove={uiState.onRemoveElement}
      onChange={
        uiState.reactions ? uiState.reactions.onSystemElementChange : () => {}
      }
      onChangeDebounced={
        uiState.reactions
          ? uiState.reactions.onSystemElementChangeDebounced
          : () => {}
      }
      showWaterSupplyVideo={
        uiState.plan ? uiState.plan.showWaterSupplyVideo : false
      }
      setShowWaterSupplyVideo={
        uiState.plan ? uiState.plan.setShowWaterSupplyVideo : () => {}
      }
      waterSupplyDialog={() => {
        const labels = uiState.settingsState
          ? uiState.settingsState.texts.steps.systemElements.waterSupplyDialog
          : null;

        if (hasWaterSupplyVideoLink) {
          uiState.showConfirm({
            title: labels ? formatMessage({ id: labels.title }) : null,
            description: labels ? formatMessage({ id: labels.text }) : "info",
            icon: <BigWaterSupplySVG />,
            cancelLabel: labels
              ? formatMessage({ id: labels.cancelButton })
              : "cancel",
            confirmLabel: labels
              ? formatMessage({ id: labels.confirmButton })
              : "confirm",
            confirm: async () => {
              try {
                if (uiState.plan) {
                  uiState.plan.setShowWaterSupplyVideo();
                }
              } catch (e) {}
            },
          });
        }
      }}
      hasWaterSupplyVideoLink={hasWaterSupplyVideoLink}
      showWaterVolumeError={uiState.showWaterVolumeError}
      labels={
        uiState.settingsState
          ? uiState.settingsState.texts.properties.systemElements
          : null
      }
    />
  );
};

SystemElementPropertiesWithState = observer(SystemElementPropertiesWithState);
export default SystemElementPropertiesWithState;
