import React from "react";
import { observer } from "mobx-react";

import { useIntl } from "react-intl";
import useStores from "../../hooks/useStores";

const SprinklersCoverageLegend = ({ legend }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="coverage-legend">
      {legend.map(({ color, label }, key) => {
        return (
          <div key={key} className="legend-item">
            <i style={{ background: color }}></i>
            {formatMessage({ id: label })}
          </div>
        );
      })}
    </div>
  );
};

let SprinklersCoverageLegendWithState = () => {
  const { uiState } = useStores();
  return (
    <>
      {uiState.plan &&
        uiState.plan.precipitationCoverage &&
        uiState.calculatingTitle == null &&
        uiState.stepIdx === uiState.stepIdxByName("sprinklers") && (
          <SprinklersCoverageLegend
            legend={uiState.settingsState.precipitationBins}
          />
        )}
    </>
  );
};

SprinklersCoverageLegendWithState = observer(SprinklersCoverageLegendWithState);
export default SprinklersCoverageLegendWithState;
