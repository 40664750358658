import { sizeInMetersByPixel } from "@dvsproj/ipat-core/planUtils";
import { findFittingByTubeType, maxOfArray } from "../common-utils";
import { Settings } from "../../../config/settings-types";
import { IRRIGATION_TUBE_TYPE } from "../../../config/dictionary";
import { BomItem, Pipeline, Plan } from "../../../config/plan-types";

function irrigatorPipelineMultiplicator(pipeline: Pipeline) {
  if (pipeline.isTubingPipe) return undefined;

  if (pipeline.lineType === "dripline" && pipeline.driplinePoint != null) {
    return pipeline.driplinePoint.driplineValvesCount;
  }

  return 1;
}

function _findPipesAndConnectors(
  settings: Settings,
  tubeType: IRRIGATION_TUBE_TYPE,
  length: number
) {
  length = Math.round(length);

  let bomItems: BomItem[] = [];

  const { bomList } = settings;
  const { peMainLine } = settings.elements;

  const tubesByType = peMainLine.filter((tube) => tubeType === tube.type);

  const tubing = tubesByType.find(
    (tube) => length >= tube.minLength && length <= tube.maxLength
  );

  if (tubing != null) {
    bomItems.push({
      bomId: tubing.bomId,
      qualityList: bomList[tubing.bomId],
      quantity: 1,
    });
    return bomItems;
  }

  const maxTubing = maxOfArray(tubesByType, "maxLength");
  if (maxTubing == null) throw new Error("Irrator tubing not found");

  let maxTubingQuantity = Math.floor(length / maxTubing.maxLength);

  length = length - maxTubingQuantity * maxTubing.maxLength;
  const reminderTube = tubesByType.find(
    ({ minLength, maxLength }) => length >= minLength && length <= maxLength
  );

  if (reminderTube != null) {
    bomItems.push({
      bomId: reminderTube.bomId,
      qualityList: bomList[reminderTube.bomId],
      quantity: 1,
    });
  } else {
    maxTubingQuantity++;
  }

  bomItems.push({
    bomId: maxTubing.bomId,
    qualityList: bomList[maxTubing.bomId],
    quantity: maxTubingQuantity,
  });

  // add connectors (sum(bomItems.quantity) - 1)

  return bomItems;
}

function pipesAndFittingsForIrrigators(settings: Settings, plan: Plan) {
  const { bomList, elements } = settings;

  const {
    elbowFitting,
    tFitting,
    endPiece,
    reducedConnector,
    connector180,
    fittingRemoveTool,
    blulockPipe15_10m,
  } = elements;

  const irrigatorPipelines: Pipeline[] = [];
  for (const pipeline of plan.pipelines) {
    const multiplicator = irrigatorPipelineMultiplicator(pipeline);
    if (multiplicator == null) continue;

    Array.from({ length: multiplicator }).forEach(() => {
      irrigatorPipelines.push(pipeline);
    });
  }

  const length: number = irrigatorPipelines.reduce((acc, pipeline) => {
    return acc + sizeInMetersByPixel(pipeline.totalLength, plan.scale);
  }, 0);

  let bomItems: BomItem[] = [];

  bomItems.push(
    ..._findPipesAndConnectors(settings, plan.irrigationTubeType, length)
  );

  const lPointQuantity = irrigatorPipelines.reduce((acc, pipeline) => {
    let quantity = pipeline.points.filter(
      (p) => p.pointType === "l-point" && p.linesCount === 2
    ).length;

    return acc + Math.min(quantity + 2, 4); // IPAT-274
  }, 0);

  if (lPointQuantity > 0) {
    const elbowFittingByType = findFittingByTubeType(
      plan.irrigationTubeType,
      elbowFitting
    );

    bomItems.push({
      bomId: elbowFittingByType.bomId,
      qualityList: bomList[elbowFittingByType.bomId],
      quantity: lPointQuantity,
    });
  }

  const tPointQuantity = irrigatorPipelines.reduce((acc, pipeline) => {
    let quantity = pipeline.points.filter(
      (p) => p.pointType === "t-point"
    ).length;

    const additionalPointQuantity = pipeline.points.filter((p) =>
      ["rzws-point", "raised-bed-point"].includes(p.pointType)
    ).length;

    if (additionalPointQuantity > 1) {
      quantity += additionalPointQuantity - 1; // IPAT-460
    }

    return acc + quantity;
  }, 0);

  if (tPointQuantity > 0) {
    const tFittingByType = findFittingByTubeType(
      plan.irrigationTubeType,
      tFitting
    );

    bomItems.push({
      bomId: tFittingByType.bomId,
      qualityList: bomList[tFittingByType.bomId],
      quantity: tPointQuantity,
    });
  }

  const endPointsQuantity =
    irrigatorPipelines.reduce((acc, pipeline) => {
      const quantity = pipeline.points.filter(
        (p) => p.pointType === "l-point" && p.linesCount <= 1
      ).length;

      return acc + quantity;
    }, 0) + Math.ceil(irrigatorPipelines.length / 2);

  if (endPointsQuantity > 0) {
    const endPieceByType = findFittingByTubeType(
      plan.irrigationTubeType,
      endPiece
    );

    bomItems.push({
      bomId: endPieceByType.bomId,
      qualityList: bomList[endPieceByType.bomId],
      quantity: endPointsQuantity,
    });
  }

  const reducedConnectorQuantity =
    irrigatorPipelines.reduce((acc, pipeline) => {
      const quantity = pipeline.points.filter(
        (p) => p.pointType === "sprinkler-point" && p.linesCount === 1
      ).length;

      return acc + quantity;
    }, 0) + Math.ceil(irrigatorPipelines.length / 2);

  if (reducedConnectorQuantity > 0) {
    const reducedConnectorByType = findFittingByTubeType(
      plan.irrigationTubeType,
      reducedConnector
    );

    bomItems.push({
      bomId: reducedConnectorByType.bomId,
      qualityList: bomList[reducedConnectorByType.bomId],
      quantity: reducedConnectorQuantity,
    });
  }

  const connectorByType = findFittingByTubeType(
    plan.irrigationTubeType,
    connector180
  );

  bomItems.push({
    bomId: connectorByType.bomId,
    qualityList: bomList[connectorByType.bomId],
    quantity: 3,
  });

  bomItems.push({
    bomId: blulockPipe15_10m.bomId,
    qualityList: bomList[blulockPipe15_10m.bomId],
    quantity: 1,
  });

  const fittingRemoveToolByType = findFittingByTubeType(
    plan.irrigationTubeType,
    fittingRemoveTool
  );

  bomItems.push({
    bomId: fittingRemoveToolByType.bomId,
    qualityList: bomList[fittingRemoveToolByType.bomId],
    quantity: 1,
  });

  return bomItems;
}

export { pipesAndFittingsForIrrigators };
