import { observer } from "mobx-react-lite";
import HeaderButton from "./HeaderButton";

import { calcApi } from "../../utils/api";
import { downloadFileFromBase64 } from "../elements/Link";

import { ReactComponent as CameraSVG } from "../../assets/camera.svg";

let ImageButton = ({ planId, plan, currentStep }) => {
  return (
    <HeaderButton
      onClick={async () => {
        if (plan != null) {
          let planJSON = plan.toJSON;
          if (plan.background) {
            const { width, height, src } = plan.background;
            planJSON.background = {
              width,
              height,
              src,
            };
          }

          const layouts = {};
          [
            { name: "background", step: 0 },
            { name: "overlay", step: 0 },
            { name: "grid", step: 0 },
            { name: "area", step: 1 },
            { name: "trenches", step: 4 },
            { name: "sprinkler", step: 2 },
            { name: "systemElement", step: 3 },
            { name: "tubing", step: 4 },
            { name: "pipeline", step: 4 },
          ]
            .filter(({ step }) => step <= currentStep)
            .forEach(({ name }) => (layouts[name] = 0));

          const textsVisibility = {
            areaWatermark: currentStep === 1,
            sprinklerElements: currentStep > 2,
            sprinklersIrrigation: currentStep === 2,
            sprinklersTexts: currentStep === 2,
          };
          const blob = await calcApi.generateImage(
            planId,
            planJSON,
            layouts,
            textsVisibility
          );

          downloadFileFromBase64(blob, "image.png");
        }
      }}
      Icon={CameraSVG}
    />
  );
};
ImageButton = observer(ImageButton);

export default ImageButton;
