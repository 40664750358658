import React from "react";
import AreaControlItem from "./AreaControlItem";
import AreaControlRotate from "./AreaControlRotate";

const deltaByStartAngle = (deltaX, deltaY, startAngle) => {
  var dY =
    deltaX * Math.sin(startAngle * (Math.PI / 180)) -
    deltaY * Math.cos(startAngle * (Math.PI / 180));

  var dX =
    deltaX * Math.cos(startAngle * (Math.PI / 180)) +
    deltaY * Math.sin(startAngle * (Math.PI / 180));

  return {
    deltaX: dX,
    deltaY: -dY,
  };
};

export const RectAndCircleAreaControl = ({
  width,
  height,
  disableMove,
  changeWidth,
  changeHeight,
  changeCenter,
  startAngle,
  changeAngle,
  zoomDelta,
  scale,
  onAfter = () => {},
}) => {
  return (
    <g className="area-control">
      <AreaControlRotate
        x={0}
        y={-height / 2}
        zoomDelta={zoomDelta}
        height={height}
        disableMove={disableMove}
        onAfter={onAfter}
        changeAngle={changeAngle}
        startAngle={startAngle}
      />
      <AreaControlItem
        id={`control-center-top`}
        x={0}
        y={-height / 2}
        zoomDelta={zoomDelta}
        scale={scale}
        disableMove={disableMove}
        onAfter={onAfter}
        move={({ deltaX, deltaY }) => {
          var newDelta = deltaByStartAngle(deltaX, deltaY, startAngle);
          changeHeight(-newDelta.deltaY);

          var centerDelta = deltaByStartAngle(
            0,
            0.5 * newDelta.deltaY,
            180 - startAngle
          );
          changeCenter(-centerDelta.deltaX, -centerDelta.deltaY);
        }}
      />
      <AreaControlItem
        id={`control-right-top`}
        x={width / 2}
        y={-height / 2}
        zoomDelta={zoomDelta}
        scale={scale}
        disableMove={disableMove}
        onAfter={onAfter}
        move={({ deltaX, deltaY }) => {
          var newDelta = deltaByStartAngle(deltaX, deltaY, startAngle);
          changeHeight(-newDelta.deltaY);
          changeWidth(newDelta.deltaX);

          var centerDelta = deltaByStartAngle(
            0.5 * newDelta.deltaX,
            0.5 * newDelta.deltaY,
            180 - startAngle
          );
          changeCenter(-centerDelta.deltaX, -centerDelta.deltaY);
        }}
      />
      <AreaControlItem
        id={`control-right-middle`}
        x={width / 2}
        y={0}
        zoomDelta={zoomDelta}
        scale={scale}
        disableMove={disableMove}
        onAfter={onAfter}
        move={({ deltaX, deltaY }) => {
          var newDelta = deltaByStartAngle(deltaX, deltaY, startAngle);
          changeWidth(newDelta.deltaX);

          var centerDelta = deltaByStartAngle(
            0.5 * newDelta.deltaX,
            0,
            180 - startAngle
          );
          changeCenter(-centerDelta.deltaX, -centerDelta.deltaY);
        }}
      />
      <AreaControlItem
        id={`control-right-bottom`}
        x={width / 2}
        y={height / 2}
        zoomDelta={zoomDelta}
        scale={scale}
        hasActive={true}
        disableMove={disableMove}
        onAfter={onAfter}
        move={({ deltaX, deltaY }) => {
          var newDelta = deltaByStartAngle(deltaX, deltaY, startAngle);
          changeWidth(newDelta.deltaX);
          changeHeight(newDelta.deltaY);

          var centerDelta = deltaByStartAngle(
            0.5 * newDelta.deltaX,
            0.5 * newDelta.deltaY,
            180 - startAngle
          );
          changeCenter(-centerDelta.deltaX, -centerDelta.deltaY);
        }}
      />
      <AreaControlItem
        id={`control-center-bottom`}
        x={0}
        y={height / 2}
        zoomDelta={zoomDelta}
        scale={scale}
        disableMove={disableMove}
        onAfter={onAfter}
        move={({ deltaX, deltaY }) => {
          var newDelta = deltaByStartAngle(deltaX, deltaY, startAngle);
          changeHeight(newDelta.deltaY);

          var centerDelta = deltaByStartAngle(
            0,
            0.5 * newDelta.deltaY,
            180 - startAngle
          );
          changeCenter(-centerDelta.deltaX, -centerDelta.deltaY);
        }}
      />
      <AreaControlItem
        id={`control-left-bottom`}
        x={-width / 2}
        y={height / 2}
        zoomDelta={zoomDelta}
        scale={scale}
        disableMove={disableMove}
        onAfter={onAfter}
        move={({ deltaX, deltaY }) => {
          var newDelta = deltaByStartAngle(deltaX, deltaY, startAngle);
          changeHeight(newDelta.deltaY);
          changeWidth(-newDelta.deltaX);

          var centerDelta = deltaByStartAngle(
            0.5 * newDelta.deltaX,
            0.5 * newDelta.deltaY,
            180 - startAngle
          );
          changeCenter(-centerDelta.deltaX, -centerDelta.deltaY);
        }}
      />
      <AreaControlItem
        id={`control-left-middle`}
        x={-width / 2}
        y={0}
        zoomDelta={zoomDelta}
        scale={scale}
        disableMove={disableMove}
        onAfter={onAfter}
        move={({ deltaX, deltaY }) => {
          var newDelta = deltaByStartAngle(deltaX, deltaY, startAngle);
          changeWidth(-newDelta.deltaX);

          var centerDelta = deltaByStartAngle(
            0.5 * newDelta.deltaX,
            0,
            180 - startAngle
          );
          changeCenter(-centerDelta.deltaX, -centerDelta.deltaY);
        }}
      />
      <AreaControlItem
        id={`control-left-top`}
        x={-width / 2}
        y={-height / 2}
        zoomDelta={zoomDelta}
        scale={scale}
        disableMove={disableMove}
        onAfter={onAfter}
        move={({ deltaX, deltaY }) => {
          var newDelta = deltaByStartAngle(deltaX, deltaY, startAngle);
          changeWidth(-newDelta.deltaX);
          changeHeight(-newDelta.deltaY);

          var centerDelta = deltaByStartAngle(
            0.5 * newDelta.deltaX,
            0.5 * newDelta.deltaY,
            180 - startAngle
          );
          changeCenter(-centerDelta.deltaX, -centerDelta.deltaY);
        }}
      />
    </g>
  );
};

export const FreeDrawingAreaControl = ({
  zoomDelta,
  scale,
  points,
  disableMove,
  lines,
  color,
  clearBezierPoint,
  removePoint,
  addPoint,
  move,
  shift,
  onAfter,
}) => {
  let controls = [];

  if (lines && lines.length) {
    lines.forEach((line, i) => {
      controls.push(
        <AreaControlItem
          key={`control-line-${i}`}
          id={`control-line-${i}`}
          d={`M ${line.start.x} ${line.start.y} 
          Q ${
            line.control
              ? line.control.x + " " + line.control.y
              : line.end.x + " " + line.end.y
          } 
          ${line.end.x} ${line.end.y}`}
          color={color}
          disableMove={disableMove}
          addPoint={(e) => addPoint(e, 2 * i)}
          move={({ x, y, deltaX, deltaY }) => {
            shift(2 * i + 2, deltaX, deltaY);
          }}
          onAfter={onAfter}
          type="line"
          x={0}
          y={0}
        />
      );
    });
  }

  points.forEach((point, i) => {
    controls.push(
      <AreaControlItem
        key={`control-${i}`}
        id={`control-${i}`}
        type={point.type}
        x={point.x}
        y={point.y}
        zoomDelta={zoomDelta}
        scale={scale}
        disableMove={disableMove}
        move={({ x, y, deltaX, deltaY }) => {
          move(i, x + deltaX, y + deltaY);
        }}
        onAfter={onAfter}
        dblClick={
          point.isControlPoint
            ? () => clearBezierPoint(i)
            : () => removePoint(i)
        }
      />
    );
  });

  return <g className="area-control">{controls}</g>;
};
