import { observer } from "mobx-react";

import { action } from "mobx";

import { sizeInMetersByPixel } from "@dvsproj/ipat-core/planUtils";
import { toFixedPrecision } from "@dvsproj/ipat-core/formatter";

import Properties from "../Properties";
import ProgressBar from "../../elements/ProgressBar";
import {
  LPoint,
  TPoint,
  SystemElPoint,
  SprinklerPoint,
  ConnectionPoint,
  PipelineItem,
  WaterTapPoint,
} from "./PipelinePropertiesItem";
import Scroll from "../../elements/Scroll";

import {
  ConnectionPointSVG,
  TPointSVG,
  ConnectPipeSVG,
  SprinklerPointSVG,
  StartPointSVG,
  CircuitSVG,
  LPointSVG,
  RzwsPointSVG,
  RaisedBedPointSVG,
} from "../../pipes/PipePointIcons";
import { ReactComponent as PipeSVG } from "./../../../assets/pipe-length.svg";
import { ReactComponent as ValveBoxSVG } from "./../../../assets/valve-box.svg";
import { ReactComponent as WaterSupplySVG } from "./../../../assets/water-supply.svg";
import { ReactComponent as WaterTapSVG } from "./../../../assets/water-tap.svg";
import { ReactComponent as OveralLengthSVG } from "./../../../assets/overal-length-icon.svg";
import { ReactComponent as FloverAreaSVG } from "./../../../assets/floverarea-icon.svg";
import { ReactComponent as LessWaterSVG } from "./../../../assets/less-water.svg";
import { ReactComponent as NoGoSVG } from "./../../../assets/no-go.svg";

import { ReactComponent as AirCompressorSVG } from "./../../../assets/air-compressor.svg";
import { ReactComponent as FertilizerSVG } from "./../../../assets/fertilizer.svg";
import { ReactComponent as WaterFilterSVG } from "./../../../assets/water-filter.svg";
import { ReactComponent as WaterMeterSVG } from "./../../../assets/water-meter.svg";
import { ReactComponent as CombiBoxSVG } from "./../../../assets/combi-box-big.svg";

import { useIntl } from "react-intl";
import useStores from "../../../hooks/useStores";

const CircuitProperties = ({
  color,
  pipelineDisabled,
  totalLength,
  flowerArea,
  shapeLimit,
  waterQuantity,
  driplineValvesCount,
  maxWaterVolume,
  lineType,
  onPipelineDisable = () => {},
  onRemovePipeline = () => {},
  onPipelineChange = () => {},
  labels,
  scale,
  precision,
}) => {
  const { formatMessage, formatNumber } = useIntl();

  let WaterComponent;
  let FloverArea;
  switch (lineType) {
    case "sprinkler":
    case "rzws-line":
    case "raised-bed-line":
      WaterComponent = (
        <>
          <div className="pipeline-properties-item">
            <ProgressBar
              hasError={shapeLimit > maxWaterVolume}
              value={
                ((toFixedPrecision(shapeLimit, 2) -
                  toFixedPrecision(waterQuantity, 2)) *
                  100) /
                shapeLimit
              }
            />
          </div>
          <div className="water-consumption">
            <div className="total">
              <div
                className="small-text"
                dangerouslySetInnerHTML={{
                  __html: formatMessage({
                    id: labels["circuit"].waterTotalConsumption,
                  }),
                }}
              ></div>
              <div>
                <span className="item-text">
                  {formatNumber(toFixedPrecision(shapeLimit / 1000, 2), {
                    minimumFractionDigits: 2,
                  })}
                </span>
                <span className="small-text">
                  m<sup>3</sup>/h
                </span>
              </div>
            </div>
            <div className="remaining">
              <div className="small-text">
                {formatMessage({ id: labels["circuit"].waterRemaining })}
              </div>
              <div>
                <span className="item-text">
                  {formatNumber(
                    toFixedPrecision((shapeLimit - waterQuantity) / 1000, 2),
                    {
                      minimumFractionDigits: 2,
                    }
                  )}
                </span>
                <span className="small-text">
                  m<sup>3</sup>/h
                </span>
              </div>
            </div>
          </div>
        </>
      );
      break;
    case "dripline":
      FloverArea = (
        <>
          <PipelineItem
            icon={<FloverAreaSVG />}
            label={
              labels
                ? formatMessage({ id: labels["circuit"].floverArea })
                : "area of the\nflowerbed"
            }
            text={formatNumber(toFixedPrecision(flowerArea, precision))}
            unit="m<sup>2</sup>"
            textInfo={
              driplineValvesCount > 1
                ? (labels
                    ? formatMessage({ id: labels["circuit"].driplineValves })
                    : "The number of valves the irrigation area should be connected to: {valvesCount}"
                  ).replace("{valvesCount}", driplineValvesCount)
                : null
            }
          />
        </>
      );
      break;
    default:
      break;
  }

  return (
    <Properties
      hasScroll={false}
      onDisable={onPipelineDisable}
      disabled={pipelineDisabled}
      onRemove={onRemovePipeline}
      onChange={onPipelineChange}
      header={{
        icon: <CircuitSVG color={color} />,
        title: labels
          ? formatMessage({ id: labels["circuit"].title })
          : "Circuit",
      }}
      hasClose={false}
      hasTitle={false}
    >
      <div className="pipeline-properties">
        <PipelineItem
          icon={<OveralLengthSVG />}
          label={
            labels
              ? formatMessage({ id: labels["circuit"].overallLenght })
              : "overall length"
          }
          text={formatNumber(
            toFixedPrecision(sizeInMetersByPixel(totalLength, scale), precision)
          )}
          unit="m"
        />
        {FloverArea}
        {WaterComponent}
      </div>
    </Properties>
  );
};

let PipelineProperties = ({
  onDisable = () => {},
  disabled = false,
  onRemove = () => {},
  onChange = () => {},
  changeDripline = () => {},
  type = "pipe",
  length,
  scale,
  linesCount = 0,
  precision,
  canBeDripline,
  labels,
  color,
  sprinklerWaterflow,
}) => {
  const { formatMessage, formatNumber } = useIntl();

  let element;
  switch (type) {
    case "water-tap-point":
      element = {
        header: {
          icon: <WaterTapSVG width="36px" height="36px" />,
          title: labels
            ? formatMessage({ id: labels["water-tap-point"].title })
            : "water-tap-point",
        },
        content: (
          <WaterTapPoint
            type={type}
            labels={labels}
            hasWaterTap={true}
            disabled={disabled}
          />
        ),
      };
      break;
    case "water-supply":
      element = {
        header: {
          icon: <WaterSupplySVG width="36px" height="36px" />,
          title: labels
            ? formatMessage({ id: labels["water-supply"].title })
            : "water-supply",
        },
        content: <SystemElPoint labels={labels} type="water-supply" />,
      };
      break;
    case "start-point":
      element = {
        header: {
          icon: <ValveBoxSVG />,
          title: labels
            ? formatMessage({ id: labels["valve-point"].title })
            : "valve-point",
        },
        content: <SystemElPoint labels={labels} type="valve-point" />,
      };
      break;
    case "l-point":
      element = {
        header: {
          icon:
            linesCount > 1 ? (
              <LPointSVG color={color} />
            ) : (
              <StartPointSVG color={color} />
            ),
          title: labels
            ? formatMessage({ id: labels["l-point"].title })
            : "l-point",
        },
        content: canBeDripline ? (
          <ConnectionPoint
            type={type}
            labels={labels}
            hasDripline={false}
            changeDripline={changeDripline}
            disabled={disabled}
          />
        ) : (
          <LPoint labels={labels} linesCount={linesCount} />
        ),
      };
      break;
    case "t-point":
      element = {
        header: {
          icon: <TPointSVG color={color} />,
          title: labels
            ? formatMessage({ id: labels["t-point"].title })
            : "t-point",
        },
        content: <TPoint labels={labels} />,
      };
      break;

    case "dripline-point":
      element = {
        header: {
          icon: <ConnectionPointSVG color={color} />,
          title: labels
            ? formatMessage({ id: labels["dripline-point"].title })
            : "dripline-point",
        },
        content: (
          <ConnectionPoint
            type={type}
            labels={labels}
            hasDripline={true}
            changeDripline={changeDripline}
            disabled={disabled}
          />
        ),
      };
      break;
    case "sprinkler-point":
      element = {
        header: {
          icon: <SprinklerPointSVG color={color} />,
          title: labels
            ? formatMessage({ id: labels["sprinkler-point"].title })
            : "sprinkler-point",
        },
        content: (
          <SprinklerPoint
            type={type}
            sprinklerWaterflow={toFixedPrecision(sprinklerWaterflow / 1000, 2)}
            labels={labels}
          />
        ),
      };
      break;
    case "pipe":
      element = {
        header: {
          icon: <ConnectPipeSVG color={color} />,
          title: labels ? formatMessage({ id: labels["pipe"].title }) : "pipe",
        },
        content: (
          <PipelineItem
            icon={<PipeSVG />}
            label={
              labels ? formatMessage({ id: labels["pipe"].length }) : "pipe"
            }
            text={formatNumber(
              toFixedPrecision(sizeInMetersByPixel(length, scale), precision)
            )}
            unit="m"
          />
        ),
      };
      break;
    case "rzws-point":
      element = {
        header: {
          icon: <RzwsPointSVG />,
          title: labels
            ? formatMessage({ id: labels["sprinkler-point"].title })
            : "sprinkler-point",
        },
        content: (
          <SprinklerPoint
            type={type}
            sprinklerWaterflow={toFixedPrecision(sprinklerWaterflow / 1000, 2)}
            labels={labels}
          />
        ),
      };
      break;
    case "raised-bed-point":
      element = {
        header: {
          icon: <RaisedBedPointSVG />,
          title: labels
            ? formatMessage({ id: labels["sprinkler-point"].title })
            : "sprinkler-point",
        },
        content: (
          <SprinklerPoint
            type={type}
            sprinklerWaterflow={toFixedPrecision(sprinklerWaterflow / 1000, 2)}
            labels={labels}
          />
        ),
      };
      break;
    case "water-meter":
      element = {
        header: {
          icon: <WaterMeterSVG />,
          title: labels
            ? formatMessage({ id: labels["water-meter"].title })
            : "water-meter",
        },
        content: <SystemElPoint labels={labels} type="water-meter" />,
      };
      break;
    case "water-filter":
      element = {
        header: {
          icon: <WaterFilterSVG />,
          title: labels
            ? formatMessage({ id: labels["water-filter"].title })
            : "water-filter",
        },
        content: <SystemElPoint labels={labels} type="water-filter" />,
      };
      break;

    case "combi-box":
      element = {
        header: {
          icon: <CombiBoxSVG />,
          title: labels
            ? formatMessage({ id: labels["combi-box"].title })
            : "combi-box",
        },
        content: <SystemElPoint labels={labels} type="combi-box" />,
      };
      break;
    case "air-compressor":
      element = {
        header: {
          icon: <AirCompressorSVG />,
          title: labels
            ? formatMessage({ id: labels["air-compressor"].title })
            : "air-compressor",
        },
        content: <SystemElPoint labels={labels} type="air-compressor" />,
      };
      break;
    case "fertilizer":
      element = {
        header: {
          icon: <FertilizerSVG />,
          title: labels
            ? formatMessage({ id: labels["fertilizer"].title })
            : "fertilizer",
        },
        content: <SystemElPoint labels={labels} type="fertilizer" />,
      };
      break;
    default:
      break;
  }
  if (element == null) return null;
  return (
    <Properties
      hasScroll={false}
      onDisable={onDisable}
      disabled={disabled}
      onRemove={onRemove}
      onChange={onChange}
      header={element.header}
      hasClose={false}
    >
      <div className="pipeline-properties">{element.content}</div>
    </Properties>
  );
};
PipelineProperties = observer(PipelineProperties);

let ElementAndPipelineProperties = ({
  selectedElement,
  selectedPipeline,
  planIsEditable,
  ...props
}) => {
  if (!selectedPipeline) return null;

  const params = {
    type:
      selectedElement.type === "pipe"
        ? selectedElement.type
        : selectedElement.pointType,
    linesCount:
      selectedElement.type === "pipe" ? 0 : selectedElement.linesCount,
    canBeDripline:
      selectedElement.type === "pipeline-point" &&
      selectedElement.canBeDripline,
    color: selectedPipeline.color,
    sprinklerWaterflow:
      selectedElement.type === "pipeline-point" &&
      (selectedElement.isSprinklerPoint ||
        selectedElement.isRzwsPoint ||
        selectedElement.isRaisedBedPoint) &&
      selectedElement.sprinkler
        ? selectedElement.sprinkler.waterflow
        : 0,
    length: selectedElement.type === "pipe" ? selectedElement.length : null,
    onDisable: !selectedPipeline.disabled
      ? selectedElement.onDisable
      : () => {},
    disabled:
      selectedElement.disabled ||
      selectedElement.isConnectionPoint ||
      !planIsEditable,
    pipelineDisabled: selectedPipeline.disabled,
    totalLength: selectedPipeline.totalLength,
    flowerArea: selectedPipeline.flowerArea,
    shapeLimit: selectedPipeline.shapeLimit,
    waterQuantity: selectedPipeline.waterQuantity,
    onPipelineDisable: selectedPipeline.onDisable,
    driplineValvesCount: selectedElement.isDriplinePoint
      ? selectedElement.driplineValvesCount
      : null,
  };

  return (
    <>
      <PipelineProperties {...params} {...props} />
      {selectedPipeline &&
        !(
          selectedElement.type === "pipeline-point" &&
          selectedElement.isSystemElementPoint
        ) && (
          <CircuitProperties
            {...params}
            {...props}
            lineType={selectedPipeline.lineType}
          />
        )}
    </>
  );
};
ElementAndPipelineProperties = observer(ElementAndPipelineProperties);

let CircuitOnDrawProperties = ({
  drawingPipeLengthInPx,
  totalWaterQuantity,
  hoveredPipeline,
  selectedPipeline,
  maxWaterVolume,
  errorMessages,
  labels,
  scale,
  precision,
  showWaterConsumption,
}) => {
  const { formatMessage, formatNumber } = useIntl();

  return (
    <Properties
      hasScroll={false}
      onDisable={() => {}}
      disabled={false}
      onRemove={() => {}}
      onChange={() => {}}
      header={{
        icon: <CircuitSVG color={hoveredPipeline.color} />,
        title: labels
          ? formatMessage({ id: labels["circuit"].title })
          : "Circuit",
      }}
      hasClose={false}
      hasTitle={false}
    >
      <div className="pipeline-draw-properties">
        <div
          className={`pipeline-properties ${
            errorMessages.length === 0 && showWaterConsumption
              ? "message success"
              : ""
          }`}
        >
          <PipelineItem
            icon={<OveralLengthSVG />}
            label={
              labels
                ? formatMessage({ id: labels["circuit"].newOverallLenght })
                : "overall length"
            }
            text={formatNumber(
              toFixedPrecision(
                sizeInMetersByPixel(
                  hoveredPipeline.totalLength +
                    selectedPipeline.totalLength +
                    drawingPipeLengthInPx,
                  scale
                ),
                precision
              )
            )}
            unit="m"
          />
        </div>
        {errorMessages.length > 0 ? (
          <div className="pipeline-properties message error">
            <div className="pipeline-properties-item">
              {errorMessages[0].title && (
                <div className="title">
                  {formatMessage({ id: errorMessages[0].title })}
                </div>
              )}
              <div>{formatMessage({ id: errorMessages[0].text })}</div>
            </div>
            <div className="error-img-wrap">
              <div className="error-img">
                {errorMessages[0].type === "water" ? (
                  <LessWaterSVG />
                ) : (
                  <NoGoSVG />
                )}
              </div>
            </div>
          </div>
        ) : showWaterConsumption ? (
          <div className="pipeline-properties">
            <div className="pipeline-properties-item">
              <ProgressBar
                hasError={totalWaterQuantity > maxWaterVolume}
                value={
                  ((toFixedPrecision(selectedPipeline.shapeLimit, 2) -
                    toFixedPrecision(totalWaterQuantity, 2)) *
                    100) /
                  selectedPipeline.shapeLimit
                }
              />
            </div>
            <div className="water-consumption">
              <div className="total">
                <div
                  className="small-text"
                  dangerouslySetInnerHTML={{
                    __html: formatMessage({
                      id: labels["circuit"].newWaterTotalConsumption,
                    }),
                  }}
                ></div>
                <div>
                  <span className="item-text">
                    {formatNumber(
                      toFixedPrecision(selectedPipeline.shapeLimit / 1000, 2),
                      {
                        minimumFractionDigits: 2,
                      }
                    )}
                  </span>
                  <span className="small-text">
                    m<sup>3</sup>/h
                  </span>
                </div>
              </div>
              <div className="remaining">
                <div className="small-text">
                  {formatMessage({ id: labels["circuit"].newWaterRemaining })}
                </div>
                <div>
                  <span className="item-text">
                    {formatNumber(
                      toFixedPrecision(
                        (selectedPipeline.shapeLimit - totalWaterQuantity) /
                          1000,
                        2
                      ),
                      {
                        minimumFractionDigits: 2,
                      }
                    )}
                  </span>
                  <span className="small-text">
                    m<sup>3</sup>/h
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Properties>
  );
};
CircuitOnDrawProperties = observer(CircuitOnDrawProperties);

let PipelinePropertiesWithState = () => {
  const { uiState } = useStores();
  const { formatMessage } = useIntl();

  const { selectedElement, hoveredElement } = uiState;
  if (selectedElement == null) return null;

  const selectedPipeline = selectedElement.pipelines
    ? selectedElement.pipelines[0]
    : undefined;

  const hoveredPipeline =
    hoveredElement && hoveredElement.pipelines
      ? hoveredElement.pipelines[0]
      : undefined;

  const onRemovePipeline = () => {
    const confirmLabels = uiState.settingsState
      ? uiState.settingsState.dialog
      : null;
    if (!selectedPipeline.disabled) {
      uiState
        .showConfirm({
          title: confirmLabels
            ? formatMessage({ id: confirmLabels.deleteTitle })
            : "",
          description: formatMessage({
            id: uiState.settingsState.texts.tools.circuit.delete,
          }),
        })
        .then(
          action(() => {
            selectedPipeline.lines.forEach(({ id }) => {
              uiState.plan.removeElementById(id);
            });
            uiState.plan.selectedPointId = undefined;
            uiState.selectedElementId = undefined;
            if (uiState.reactions) {
              uiState.reactions.onPipelineChange();
            }
          })
        )
        .catch(() => {});
    }
  };

  const changeDripline = () => {
    if (selectedElement.type === "pipeline-point") {
      if (
        selectedElement.canBeDripline &&
        selectedElement.numberDriplinePointsInArea !== 0
      ) {
        const confirmLabels = uiState.settingsState.dialog;
        uiState.showAlert({
          title: formatMessage({ id: confirmLabels.errorTitle }),
          description: formatMessage({ id: confirmLabels.notSetDriplineText }),
          confirm: async () => {},
        });
      } else {
        selectedElement.changeDripline();
        if (uiState.reactions) {
          uiState.reactions.onPipelineChange();
        }
      }
    }
  };

  const maxWaterVolume = uiState.settingsState.maxWaterVolumeList.find(
    (v) => v.type === uiState.plan.irrigationTubeType
  )?.value;
  const labels = uiState.settingsState
    ? uiState.settingsState.texts.properties.pipeline
    : null;

  let Component = undefined;

  const errorMessages = uiState.plan.pipelineErrorMessages(
    selectedElement,
    hoveredElement
  );

  if (hoveredPipeline) {
    if (
      uiState.plan.pipelineHasDrawing &&
      (errorMessages.length > 0 ||
        (selectedPipeline && selectedPipeline.id !== hoveredPipeline.id))
    ) {
      Component = (
        <CircuitOnDrawProperties
          drawingPipeLengthInPx={uiState.drawingPipeLengthInPx}
          totalWaterQuantity={
            selectedPipeline?.waterQuantity + hoveredPipeline.waterQuantity
          }
          errorMessages={errorMessages}
          selectedPipeline={selectedPipeline}
          hoveredPipeline={hoveredPipeline}
          onRemoveSelectedPipeline={onRemovePipeline}
          onSelectedPipelineChange={
            uiState.reactions ? uiState.reactions.onPipelineChange : () => {}
          }
          showWaterConsumption={
            !(
              selectedPipeline?.lineType === "pressure-tubing" ||
              (selectedElement?.type === "pipeline-point" &&
                selectedElement?.isWaterSupplyPoint)
            )
          }
          labels={labels}
          maxWaterVolume={maxWaterVolume}
          scale={uiState.plan ? uiState.plan.scale : null}
          precision={uiState.precision}
        />
      );
    }
  }

  return (
    <Scroll className="pipeline-properties-wrap">
      {Component != null ? (
        <>{Component}</>
      ) : (
        <ElementAndPipelineProperties
          selectedPipeline={selectedPipeline}
          selectedElement={selectedElement}
          planIsEditable={uiState.planIsEditable}
          onRemove={uiState.onRemoveElement}
          onChange={
            uiState.reactions ? uiState.reactions.onPipelineChange : () => {}
          }
          scale={uiState.plan ? uiState.plan.scale : null}
          precision={uiState.precision}
          labels={labels}
          maxWaterVolume={maxWaterVolume}
          changeDripline={changeDripline}
          onRemovePipeline={onRemovePipeline}
          onPipelineChange={
            uiState.reactions ? uiState.reactions.onPipelineChange : () => {}
          }
        />
      )}
    </Scroll>
  );
};

PipelinePropertiesWithState = observer(PipelinePropertiesWithState);
export default PipelinePropertiesWithState;
