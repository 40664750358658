import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";
import BomItem from "./BomItem";

let BomGroup = ({
  groupIndex,
  label,
  childs,
  planIsEditable,
  onBomItemChange,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <div className="recom-group">{formatMessage({ id: label })}</div>
      {childs.map(
        (
          {
            productLink,
            quantity,
            quality,
            qualityList,
            name,
            description,
            price,
            pricePerUnit,
            disabled,
            articleNO,
            defaultQuantity,
            changeQuantity,
            changeQuality,
          },
          index
        ) => {
          const bom_disabled = !planIsEditable || disabled;
          return (
            <BomItem
              keyIdx={index}
              key={index}
              index={groupIndex + index}
              productLink={
                productLink ? formatMessage({ id: productLink }) : undefined
              }
              quantity={quantity}
              quality={quality}
              qualityList={qualityList}
              name={name ? formatMessage({ id: name }) : articleNO}
              description={
                description ? formatMessage({ id: description }) : undefined
              }
              price={price}
              pricePerUnit={pricePerUnit}
              changeQuantity={(val) => {
                if (!bom_disabled) {
                  changeQuantity(val);
                  onBomItemChange();
                }
              }}
              changeQuality={(val) => {
                if (!bom_disabled) {
                  changeQuality(val);
                  onBomItemChange();
                }
              }}
              defaultQuantity={defaultQuantity}
              articleNO={articleNO}
              disabled={bom_disabled}
            />
          );
        }
      )}
    </div>
  );
};
BomGroup = observer(BomGroup);

export default BomGroup;
