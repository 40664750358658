import { extendObservable, action, toJS, observable } from "mobx";
import { throttle } from "lodash";
import WorkerThreadApi from "../utils/workerThreadApi";
import { rasterize } from "@dvsproj/ipat-core/rasterizationUtils";
import { convertPlanJSON, offsetPlan } from "@dvsproj/ipat-core/planUtils";

/**
 * Follow flyweight pattern to introduce sprinklers coverage cache
 */
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (plan, settingsState) => {
  const coverageWorker = new WorkerThreadApi(
    process.env.PUBLIC_URL + "/workers/coverageWorker.js"
  );

  const innerState = observable({
    areaRaster: undefined,
    precipitationCoverage: undefined,
    sensorPrecipitationCoverage: undefined,
    sensorPrecipitationCoverageColors: undefined,

    generatePlanRaster(elements) {
      const canvas = window.document.createElement("canvas");
      canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
      return rasterize(plan.toJSON, canvas, false, elements);
    },
  });

  extendObservable(plan, {
    cleanupRaster() {
      innerState.areaRaster = undefined;
    },
    cleanupSprinklerCoverage: action(() => {
      innerState.precipitationCoverage = undefined;
      coverageWorker.send("sprinkler-precipitation-cleanup");
    }),
    fillSprinklerCoverage: throttle(async () => {
      if (innerState.areaRaster == null)
        innerState.areaRaster = innerState.generatePlanRaster(["area"]);

      if (innerState.areaRaster == null) return;

      const converted = offsetPlan(
        convertPlanJSON(plan.toJSON, "10cm"),
        innerState.areaRaster.offsetX,
        innerState.areaRaster.offsetY
      );

      coverageWorker.send("sprinkler-precipitation", {
        settingsState: toJS(settingsState),
        planAndRaster: innerState.areaRaster.irrigationMatrix,
        sprinklerSetType: plan.sprinklerSetType,
        sprinklers: converted.elements.filter(
          ({ type }) => type === "sprinkler"
        ),
      });
    }, 700),
    cleanupSensorCoverage: action(() => {
      innerState.sensorPrecipitationCoverageColors = undefined;
      innerState.sensorPrecipitationCoverage = undefined;
      coverageWorker.send("sensor-precipitation-cleanup");
    }),
    fillSensorCoverage: throttle(async () => {
      if (innerState.areaRaster == null)
        innerState.areaRaster = innerState.generatePlanRaster(["area"]);

      if (innerState.areaRaster == null) return;

      const planJSON = plan.toJSON;
      if (plan.pipelines != null) {
        coverageWorker.send("sensor-precipitation", {
          settingsState: toJS(settingsState),
          planJSON: JSON.stringify({
            ...planJSON,
            elements: [
              ...planJSON.elements.filter(({ type }) => type !== "sprinkler"),
              ...planJSON.elements
                .filter(({ type }) => type === "sprinkler")
                .map((s) => {
                  const pipeline = plan.pipelines.find((p) =>
                    p.sprinklers.some(({ id }) => id === s.id)
                  );
                  return {
                    ...s,
                    cirquitColor: pipeline?.color,
                  };
                }),
            ],
          }),
        });
      }
    }, 700),
    get precipitationCoverage() {
      return innerState.precipitationCoverage;
    },
    get sensorPrecipitationCoverage() {
      return innerState.sensorPrecipitationCoverage;
    },
    get sensorPrecipitationCoverageColors() {
      return innerState.sensorPrecipitationCoverageColors;
    },
  });

  coverageWorker.on(
    "sprinkler-precipitation",
    action(({ precipitation }) => {
      const { offsetX, offsetY } = innerState.areaRaster;
      innerState.precipitationCoverage = {
        offsetX,
        offsetY,
        ...precipitation,
      };
    })
  );

  coverageWorker.on(
    "sensor-precipitation",
    action(({ precipitation, colors }) => {
      innerState.sensorPrecipitationCoverage = precipitation;
      innerState.sensorPrecipitationCoverageColors = colors;
    })
  );
};
