import React from "react";
import { observer } from "mobx-react";

import {
  getSvgPoint,
  pixelSizeByZoom,
  sizeInMetersByPixel,
  sizeInPixelByMeters,
} from "@dvsproj/ipat-core/planUtils";
import { adjustmentPoint } from "@dvsproj/ipat-core/areaUtils";
import {
  findDefaultMpRotator,
  findDefaultMpStrip,
  findDefaultWaterSupply,
  findDefaultWaterFilter,
  findDefaultIrrigationController,
  findDefaultValveBox,
  findDefaultFertilizer,
} from "@dvsproj/ipat-core/settings/settingsUtils";

import { toFixedPrecision } from "../../utils/uiUtils";
import areaFactory from "../../store/types/areaFactory";

import SprinklerCircle from "../sprinklers/SprinklerCircle";
import SprinklerRect from "../sprinklers/SprinklerRect";
import PolygonArea from "../areas/PolygonArea";
import { ReactComponent as RzwsSVG } from "../../assets/tree-point-x24.svg";
import { ReactComponent as RaisedBedSVG } from "../../assets/raised-bed-x24.svg";
import { ReactComponent as ControllerSVG } from "../../assets/controller-x24.svg";
import { ReactComponent as WaterSupplySVG } from "../../assets/water-supply-x24.svg";
import { ReactComponent as ValveBoxSVG } from "../../assets/valve-box-x24.svg";
import { ReactComponent as WaterTapSVG } from "../../assets/water-tap-x24.svg";
import { ReactComponent as FertilizerSVG } from "../../assets/fertilizer-x24.svg";
import { ReactComponent as AirCompressorSVG } from "../../assets/air-compressor-x24.svg";
import { ReactComponent as WaterFilterSVG } from "../../assets/water-filter-x24.svg";
import { ReactComponent as WaterMeterSVG } from "../../assets/water-meter-x24.svg";
import { ReactComponent as CombiBoxSVG } from "../../assets/combi-box-x24.svg";
import { ReactComponent as SensorSVG } from "../../assets/sensor-plan-x24.svg";

import { injectIntl } from "react-intl";
import usePlanSizeValidation from "../../hooks/usePlanSizeValidation";
import useStores from "../../hooks/useStores";

const remToPx = (rem) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

const LenghtText = ({ l, x, y, angle, zoomDelta }) => {
  return (
    <text
      style={{
        fontSize: pixelSizeByZoom(remToPx(0.75), zoomDelta),
        lineHeight: pixelSizeByZoom(remToPx(0.75), zoomDelta),
        fontWeight: "bold",
        userSelect: "none",
      }}
      x={x - l.length * pixelSizeByZoom(4.3, zoomDelta)}
      y={y + pixelSizeByZoom(remToPx(0.75) / 2 - 2, zoomDelta)}
    >
      <tspan
        dx={
          !isNaN(angle)
            ? (l.length * pixelSizeByZoom(4.3, zoomDelta) +
                pixelSizeByZoom(12, zoomDelta)) *
              Math.sin(angle)
            : null
        }
        dy={
          !isNaN(angle)
            ? -pixelSizeByZoom(12, zoomDelta) * Math.cos(angle)
            : null
        }
      >
        {l} m
      </tspan>
    </text>
  );
};

let DrawingTool = injectIntl(
  class extends React.Component {
    state = {
      polygonArea: null,
      mouseCoord: null,
      clickDisabled: false,
    };

    disableClick(val) {
      const { clickDisabled } = this.state;
      if (clickDisabled !== val) {
        this.setState({ clickDisabled: val });
      }
    }

    clearMouseCoord = () => {
      const { mouseCoord } = this.state;
      if (mouseCoord) {
        this.setState({ mouseCoord: null });
      }
    };

    onMouseMove = (e) => {
      const { selectedTool } = this.props;
      const { polygonArea, clickDisabled, mouseCoord } = this.state;

      const drawingToolsWithoutPolygon = [
        "rectangle",
        "circle",
        "sprinkler-circle",
        "sprinkler-rect",
        "water-supply",
        "controller",
        "valve-box",
        "sensor",
        "water-tap-point",
        "fertilizer",
        "air-compressor",
        "water-filter",
        "water-meter",
        "rzws",
        "raised-bed",
        "combi-box",
      ];
      const selectedDrawingTool =
        selectedTool && drawingToolsWithoutPolygon.indexOf(selectedTool) > -1;

      if (
        selectedTool &&
        !selectedDrawingTool &&
        (polygonArea === null ||
          !polygonArea.points ||
          polygonArea.points.length < 1)
      ) {
        return;
      }

      if (clickDisabled) {
        if (mouseCoord != null) {
          this.setState({
            mouseCoord: null,
          });
        }
        return;
      }

      const svgP = getSvgPoint(e, this.svg);
      this.setState({
        mouseCoord: { x: svgP.x, y: svgP.y },
      });
      e.preventDefault();
    };

    onClick = (e) => {
      const { selectedTool } = this.props;
      const { polygonArea, clickDisabled } = this.state;

      if (
        selectedTool === "polygon" &&
        (clickDisabled ||
          (e.target &&
            e.target !== this.svg &&
            e.target.id !== "mouseLine" &&
            e.target.tagName !== "text" &&
            e.target.tagName !== "path"))
      ) {
        return;
      }

      const svgP = getSvgPoint(e, this.svg);

      const drawingToolsWithoutPolygon = [
        "rectangle",
        "circle",
        "sprinkler-circle",
        "sprinkler-rect",
        "water-supply",
        "controller",
        "valve-box",
        "sensor",
        "water-tap-point",
        "fertilizer",
        "air-compressor",
        "water-filter",
        "water-meter",
        "rzws",
        "raised-bed",
        "combi-box",
      ];
      const selectedDrawingToolWithoutPolygon =
        selectedTool && drawingToolsWithoutPolygon.indexOf(selectedTool) > -1;

      if (selectedDrawingToolWithoutPolygon) {
        this.clickToEndArea(e);
      } else {
        const newPoint = {
          x: Math.ceil(svgP.x),
          y: Math.ceil(svgP.y),
          type: "point",
        };
        polygonArea.addPoint(newPoint, polygonArea.points.length - 1);
        polygonArea.snapToRightAngle(polygonArea.points.length - 1);
        this.setState({ polygonArea, mouseCoord: null });
      }
      e.preventDefault();
    };

    clickToEndArea = (e) => {
      const { polygonArea } = this.state;

      const {
        add,
        selectedTool,
        zoomDelta,
        scale,
        defaultSprinkler,
        defaultSprinklerRect,
        defaultWaterSupply,
        defaultIrrigationController,
        defaultValveBox,
        defaultFertilizer,
        defaultWaterFilter,
      } = this.props;

      let element = null;
      const point = getSvgPoint(e, this.svg);
      const size = pixelSizeByZoom(240, zoomDelta);

      switch (selectedTool) {
        case "circle":
          element = {
            type: "area",
            x: point.x,
            y: point.y,
            areaType: selectedTool,
            startAngle: 0,
            width: size,
            height: size,
          };
          break;
        case "rectangle":
          element = {
            type: "area",
            x: point.x,
            y: point.y,
            areaType: selectedTool,
            startAngle: 0,
            width: size,
            height: size,
          };
          break;
        case "polygon":
          if (
            polygonArea &&
            polygonArea.points &&
            polygonArea.points.length > 3
          ) {
            polygonArea.encloseArea();
            element = polygonArea.toJSON;
          } else {
            return false;
          }
          break;
        case "sprinkler-circle":
          element = {
            type: "sprinkler",
            name: defaultSprinkler.name,
            sprinklerType: "circle",
            x: point.x,
            y: point.y,
            startAngle: 0,
            nozzleType: defaultSprinkler.nozzleType,
            circleSectorAngle: defaultSprinkler.minIrrigationAngle,
            circleRadius: sizeInPixelByMeters(
              defaultSprinkler.maxRadius,
              scale
            ),
            color: defaultSprinkler.color,
          };
          break;
        case "sprinkler-rect":
          element = {
            type: "sprinkler",
            sprinklerType: "rect",
            x: point.x,
            y: point.y,
            startAngle: 0,
            nozzleType: defaultSprinklerRect.nozzleType,
            name: defaultSprinklerRect.name,
            rectWidth: sizeInPixelByMeters(
              defaultSprinklerRect.maxWidth,
              scale
            ),
            rectHeight: sizeInPixelByMeters(
              defaultSprinklerRect.maxHeight,
              scale
            ),
            rectType: defaultSprinklerRect.type,
            color: defaultSprinklerRect.color,
            kWH: defaultSprinklerRect.kWH,
          };
          break;
        case "water-supply":
          element = {
            type: "system-element",
            systemType: selectedTool,
            x: point.x,
            y: point.y,
            waterQuality: defaultWaterSupply.waterQuality,
            waterFilter: defaultWaterSupply.waterFilter,
          };
          break;
        case "water-filter":
          element = {
            type: "system-element",
            systemType: selectedTool,
            x: point.x,
            y: point.y,
            waterFilter: defaultWaterFilter.waterFilter,
          };
          break;
        case "controller":
          element = {
            type: "system-element",
            systemType: selectedTool,
            x: point.x,
            y: point.y,
            controllerType: defaultIrrigationController.controllerType,
          };
          break;
        case "valve-box":
          element = {
            type: "system-element",
            systemType: selectedTool,
            x: point.x,
            y: point.y,
            valveBoxType: defaultValveBox.valveBoxType,
          };
          break;
        case "sensor":
          element = {
            type: "sensor",
            systemType: selectedTool,
            x: point.x,
            y: point.y,
          };
          break;
        case "water-tap-point":
          element = {
            type: "system-element",
            systemType: selectedTool,
            x: point.x,
            y: point.y,
          };
          break;
        case "fertilizer":
          element = {
            type: "system-element",
            systemType: selectedTool,
            x: point.x,
            y: point.y,
            fertilizerType: defaultFertilizer.fertilizerType,
          };
          break;
        case "air-compressor":
          element = {
            type: "system-element",
            systemType: selectedTool,
            x: point.x,
            y: point.y,
          };
          break;
        case "water-meter":
          element = {
            type: "system-element",
            systemType: selectedTool,
            x: point.x,
            y: point.y,
          };
          break;
        case "rzws":
          element = {
            type: "rzws",
            x: point.x,
            y: point.y,
          };
          break;
        case "raised-bed":
          element = {
            type: "raised-bed",
            x: point.x,
            y: point.y,
          };
          break;
        case "combi-box":
          element = {
            type: "system-element",
            systemType: selectedTool,
            x: point.x,
            y: point.y,
          };
          break;
        default:
          element = null;
      }

      if (element && add && !this.props.draggable) {
        add(element);
      }

      this.setState({ polygonArea: null, mouseCoord: null });
      e.stopPropagation();
    };

    checkAndInitArea() {
      const { selectedTool } = this.props;
      const { polygonArea } = this.state;
      if (selectedTool === "polygon" && polygonArea == null) {
        this.setState({
          polygonArea: areaFactory({
            type: "area",
            areaType: selectedTool,
            points: [],
            enclosed: false,
          }),
        });
      } else if (selectedTool !== "polygon" && polygonArea != null) {
        this.setState({ polygonArea: null });
      }
    }

    componentDidUpdate() {
      this.checkAndInitArea();
    }

    componentDidMount() {
      this.checkAndInitArea();
      if (this.svg) {
        this.svg.addEventListener(
          "touchstart",
          (e) => {
            e.preventDefault();
          },
          { passive: false }
        );
      }
    }

    render() {
      const {
        width,
        height,
        viewbox,
        draggable,
        zoomDelta,
        selectedTool,
        scale,
        defaultSprinkler,
        defaultSprinklerRect,
        precision,
        onChange,
      } = this.props;
      const { polygonArea, mouseCoord } = this.state;

      const pointSize = pixelSizeByZoom(16, zoomDelta);
      const cicleOrRectangleSize = pixelSizeByZoom(120, zoomDelta);
      let element = null;
      let firstPoint = null;
      let lastPoint = null;
      if (polygonArea && polygonArea.points && polygonArea.points.length > 0) {
        firstPoint = polygonArea.points[0];
        lastPoint =
          polygonArea.points[
            !polygonArea.enclosed
              ? polygonArea.points.length - 1
              : polygonArea.points.length - 2
          ];
      }

      if (mouseCoord && selectedTool) {
        switch (selectedTool) {
          case "circle":
            element = (
              <circle
                cx={mouseCoord.x}
                cy={mouseCoord.y}
                r={cicleOrRectangleSize}
                fill="rgba(51,188,18, 0.35)"
                stroke="rgba(51,188,18, 1)"
                strokeWidth={pixelSizeByZoom(2, zoomDelta)}
              />
            );
            break;
          case "rectangle":
            element = (
              <rect
                transform={`translate(${-cicleOrRectangleSize}, ${-cicleOrRectangleSize})`}
                x={mouseCoord.x}
                y={mouseCoord.y}
                width={cicleOrRectangleSize * 2}
                height={cicleOrRectangleSize * 2}
                fill="rgba(51,188,18, 0.35)"
                stroke="rgba(51,188,18, 1)"
                strokeWidth={pixelSizeByZoom(2, zoomDelta)}
              />
            );
            break;
          case "water-supply":
            element = (
              <g
                className="system-element"
                transform={`translate(
                                    ${
                                      mouseCoord.x -
                                      pixelSizeByZoom(12, zoomDelta)
                                    },
                                    ${
                                      mouseCoord.y -
                                      pixelSizeByZoom(12, zoomDelta)
                                    }
                                    ) scale(
                                        ${pixelSizeByZoom(1, zoomDelta)}
                                    )`}
              >
                <WaterSupplySVG />
              </g>
            );
            break;
          case "controller":
            element = (
              <g
                className="system-element"
                transform={`translate(
                                    ${
                                      mouseCoord.x -
                                      pixelSizeByZoom(12, zoomDelta)
                                    },
                                    ${
                                      mouseCoord.y -
                                      pixelSizeByZoom(12, zoomDelta)
                                    }
                                    ) scale(
                                        ${pixelSizeByZoom(1, zoomDelta)}
                                    )`}
              >
                <ControllerSVG />
              </g>
            );
            break;
          case "valve-box":
            element = (
              <g
                className="system-element"
                transform={`translate(
                                    ${
                                      mouseCoord.x -
                                      pixelSizeByZoom(12, zoomDelta)
                                    },
                                    ${
                                      mouseCoord.y -
                                      pixelSizeByZoom(12, zoomDelta)
                                    }
                                    ) scale(
                                        ${pixelSizeByZoom(1, zoomDelta)}
                                    )`}
              >
                <ValveBoxSVG />
              </g>
            );
            break;
          case "water-tap-point":
            element = (
              <g
                className="system-element"
                transform={`translate(
                                      ${
                                        mouseCoord.x -
                                        pixelSizeByZoom(12, zoomDelta)
                                      },
                                      ${
                                        mouseCoord.y -
                                        pixelSizeByZoom(12, zoomDelta)
                                      }
                                      ) scale(
                                          ${pixelSizeByZoom(1, zoomDelta)}
                                      )`}
              >
                <WaterTapSVG />
              </g>
            );
            break;
          case "fertilizer":
            element = (
              <g
                className="system-element"
                transform={`translate(
                                        ${
                                          mouseCoord.x -
                                          pixelSizeByZoom(12, zoomDelta)
                                        },
                                        ${
                                          mouseCoord.y -
                                          pixelSizeByZoom(12, zoomDelta)
                                        }
                                        ) scale(
                                            ${pixelSizeByZoom(1, zoomDelta)}
                                        )`}
              >
                <FertilizerSVG />
              </g>
            );
            break;
          case "air-compressor":
            element = (
              <g
                className="system-element"
                transform={`translate(
                                        ${
                                          mouseCoord.x -
                                          pixelSizeByZoom(12, zoomDelta)
                                        },
                                        ${
                                          mouseCoord.y -
                                          pixelSizeByZoom(12, zoomDelta)
                                        }
                                        ) scale(
                                            ${pixelSizeByZoom(1, zoomDelta)}
                                        )`}
              >
                <AirCompressorSVG />
              </g>
            );
            break;
          case "water-filter":
            element = (
              <g
                className="system-element"
                transform={`translate(
                                      ${
                                        mouseCoord.x -
                                        pixelSizeByZoom(12, zoomDelta)
                                      },
                                      ${
                                        mouseCoord.y -
                                        pixelSizeByZoom(12, zoomDelta)
                                      }
                                      ) scale(
                                          ${pixelSizeByZoom(1, zoomDelta)}
                                      )`}
              >
                <WaterFilterSVG />
              </g>
            );
            break;
          case "water-meter":
            element = (
              <g
                className="system-element"
                transform={`translate(
                                        ${
                                          mouseCoord.x -
                                          pixelSizeByZoom(12, zoomDelta)
                                        },
                                        ${
                                          mouseCoord.y -
                                          pixelSizeByZoom(12, zoomDelta)
                                        }
                                        ) scale(
                                            ${pixelSizeByZoom(1, zoomDelta)}
                                        )`}
              >
                <WaterMeterSVG />
              </g>
            );
            break;
          case "combi-box":
            element = (
              <g
                className="system-element"
                transform={`translate(
                                          ${
                                            mouseCoord.x -
                                            pixelSizeByZoom(12, zoomDelta)
                                          },
                                          ${
                                            mouseCoord.y -
                                            pixelSizeByZoom(12, zoomDelta)
                                          }
                                          ) scale(
                                              ${pixelSizeByZoom(1, zoomDelta)}
                                          )`}
              >
                <CombiBoxSVG />
              </g>
            );
            break;
          case "sensor":
            element = (
              <g
                // className="system-element"
                transform={`translate(
                                      ${
                                        mouseCoord.x -
                                        pixelSizeByZoom(17, zoomDelta)
                                      },
                                      ${
                                        mouseCoord.y -
                                        pixelSizeByZoom(17, zoomDelta)
                                      }
                                      ) scale(
                                          ${pixelSizeByZoom(1, zoomDelta)}
                                      )`}
              >
                <SensorSVG />
              </g>
            );
            break;
          case "sprinkler-circle":
            element = (
              <SprinklerCircle
                element={{
                  name: defaultSprinkler.name,
                  x: mouseCoord.x,
                  y: mouseCoord.y,
                  startAngle: 0,
                  circleSectorAngle: defaultSprinkler.minIrrigationAngle,
                  circleRadius: sizeInPixelByMeters(
                    defaultSprinkler.maxRadius,
                    scale
                  ),
                  color: defaultSprinkler.color,
                }}
                scale={scale}
                zoomDelta={zoomDelta}
                precision={precision}
                textsVisibility={{ sprinklersIrrigation: true }}
                useDefaultStyles={false}
              />
            );
            break;
          case "sprinkler-rect":
            element = (
              <SprinklerRect
                element={{
                  name: defaultSprinklerRect.name,
                  startAngle: 0,
                  // nozzleType: "PRS40",
                  rectWidth: sizeInPixelByMeters(
                    defaultSprinklerRect.maxWidth,
                    scale
                  ),
                  rectHeight: sizeInPixelByMeters(
                    defaultSprinklerRect.maxHeight,
                    scale
                  ),
                  rectType: defaultSprinklerRect.type,
                  color: defaultSprinklerRect.color,
                  kWH: defaultSprinklerRect.kWH,
                  x: mouseCoord.x,
                  y: mouseCoord.y,
                }}
                scale={scale}
                zoomDelta={zoomDelta}
                precision={precision}
                textsVisibility={{ sprinklersIrrigation: true }}
                useDefaultStyles={false}
              />
            );
            break;
          case "rzws":
            element = (
              <g
                transform={`translate(
                                    ${
                                      mouseCoord.x -
                                      pixelSizeByZoom(12, zoomDelta)
                                    },
                                    ${
                                      mouseCoord.y -
                                      pixelSizeByZoom(12, zoomDelta)
                                    }
                                    ) scale(
                                        ${pixelSizeByZoom(1, zoomDelta)}
                                    )`}
              >
                <RzwsSVG />
              </g>
            );
            break;
          case "raised-bed":
            element = (
              <g
                transform={`translate(
                                    ${
                                      mouseCoord.x -
                                      pixelSizeByZoom(12, zoomDelta)
                                    },
                                    ${
                                      mouseCoord.y -
                                      pixelSizeByZoom(12, zoomDelta)
                                    }
                                    ) scale(
                                        ${pixelSizeByZoom(1, zoomDelta)}
                                    )`}
              >
                <RaisedBedSVG />
              </g>
            );
            break;
          default:
            element = null;
        }
      }

      return (
        <svg
          style={{ zIndex: 103 }}
          ref={(c) => (this.svg = c)}
          viewBox={`${viewbox.x} ${viewbox.y} ${viewbox.w} ${viewbox.h}`}
          width={width}
          height={height}
          onTouchEnd={!draggable ? this.onClick : undefined}
          onMouseUp={!draggable ? this.onClick : undefined}
          onTouchMove={!draggable ? this.onMouseMove : undefined}
          onMouseMove={!draggable ? this.onMouseMove : undefined}
        >
          {element}

          {lastPoint && mouseCoord ? (
            <React.Fragment>
              <line
                id="mouseLine"
                x1={lastPoint.x}
                y1={lastPoint.y}
                x2={mouseCoord.x}
                y2={mouseCoord.y}
                stroke={`rgba(${polygonArea.color.r}, ${polygonArea.color.g}, ${polygonArea.color.b}, 1)`}
                strokeWidth={pixelSizeByZoom(4, zoomDelta)}
              />
              <LenghtText
                x={lastPoint.x + (mouseCoord.x - lastPoint.x) / 2}
                y={lastPoint.y + (mouseCoord.y - lastPoint.y) / 2}
                angle={Math.atan(
                  (mouseCoord.y - lastPoint.y) / (mouseCoord.x - lastPoint.x)
                )}
                l={this.props.intl.formatNumber(
                  toFixedPrecision(
                    sizeInMetersByPixel(
                      Math.sqrt(
                        Math.pow(mouseCoord.x - lastPoint.x, 2) +
                          Math.pow(mouseCoord.y - lastPoint.y, 2)
                      ),
                      scale
                    ),
                    precision
                  )
                )}
                zoomDelta={zoomDelta}
                scale={scale}
              />
            </React.Fragment>
          ) : null}

          {polygonArea &&
          polygonArea.points &&
          polygonArea.points.length > 0 ? (
            <PolygonArea
              element={polygonArea}
              getSvgPoint={(e) => getSvgPoint(e, this.svg)}
              disableClick={this.disableClick.bind(this)}
              clearMouseLine={this.clearMouseCoord.bind(this)}
              zoomDelta={zoomDelta}
              scale={scale}
              precision={precision}
              onChange={onChange}
            />
          ) : null}

          {firstPoint ? (
            <rect
              className="irrigation-area-point"
              transform={`translate(${-pointSize / 2}, ${-pointSize / 2})`}
              x={firstPoint.x}
              y={firstPoint.y}
              width={pointSize}
              height={pointSize}
              fill="#ffffff"
              strokeWidth={pixelSizeByZoom(1, zoomDelta)}
              onMouseUp={!draggable ? this.clickToEndArea : undefined}
              onTouchEnd={!draggable ? this.clickToEndArea : undefined}
              stroke="#3e4346"
            />
          ) : null}
        </svg>
      );
    }
  }
);

DrawingTool = observer(DrawingTool);

let DrawingToolWithState = ({ ...props }) => {
  const { uiState } = useStores();
  const planSizeValidation = usePlanSizeValidation();

  return (
    <DrawingTool
      {...props}
      selectedTool={uiState.selectedTool}
      zoomDelta={uiState.zoomState.zoomDelta}
      scale={uiState.plan.scale}
      add={(el) => {
        if (el.type === "sensor") {
          el.name = `S${uiState.plan?.sensors?.length + 1}`;
        }

        const element = uiState.createNewElement(el);

        if (element.type === "sprinkler") {
          const p = adjustmentPoint(
            element,
            uiState.plan.areas,
            sizeInPixelByMeters(
              uiState.settingsState.adjustmentPointThreshold,
              uiState.plan.scale
            )
          );

          if (
            p?.point &&
            (p.point.x !== element.x || p.point.y !== element.y)
          ) {
            element.drag(p.point.x, p.point.y);
            if (uiState.reactions) uiState.reactions.onSprinklerChange();
          }
        }

        planSizeValidation();
      }}
      precision={uiState.precision}
      defaultSprinkler={findDefaultMpRotator(
        uiState.settingsState,
        uiState.plan.sprinklerSetType
      )}
      defaultSprinklerRect={findDefaultMpStrip(
        uiState.settingsState,
        uiState.plan.sprinklerSetType
      )}
      defaultWaterSupply={findDefaultWaterSupply(uiState.settingsState)}
      defaultWaterFilter={findDefaultWaterFilter(uiState.settingsState)}
      defaultIrrigationController={findDefaultIrrigationController(
        uiState.settingsState
      )}
      defaultValveBox={findDefaultValveBox(uiState.settingsState)}
      defaultFertilizer={findDefaultFertilizer(uiState.settingsState)}
      draggable={uiState.selectedOtherTool === "draggable"}
      onChange={uiState.reactions ? uiState.reactions.onAreaChange : () => {}}
    />
  );
};

DrawingToolWithState = observer(DrawingToolWithState);
export default DrawingToolWithState;
