import { ReactComponent as ConsumptionSVG } from "./../../../assets/consuption-icon.svg";
import Toggle from "../../elements/Toggle";
import { useIntl } from "react-intl";

export function LPoint({ labels, linesCount }) {
  const { formatMessage } = useIntl();

  return (
    <div className="pipeline-name">
      {linesCount > 1
        ? labels
          ? formatMessage({ id: labels["l-point"].type })
          : "l-point"
        : labels
        ? formatMessage({ id: labels["end-point"].type })
        : "end-point"}
    </div>
  );
}

export function TPoint({ labels }) {
  const { formatMessage } = useIntl();

  return (
    <div className="pipeline-name">
      {labels ? formatMessage({ id: labels["t-point"].type }) : "t-point"}
    </div>
  );
}

export function SystemElPoint({ labels, type }) {
  const { formatMessage } = useIntl();
  let labelId = labels?.[type]?.type ?? labels?.[type]?.title;
  return (
    <div className="pipeline-name">
      {labelId ? formatMessage({ id: labelId }) : type}
    </div>
  );
}

export const SprinklerPoint = ({ labels, sprinklerWaterflow }) => {
  const { formatMessage, formatNumber } = useIntl();

  return (
    <PipelineItem
      icon={<ConsumptionSVG />}
      label={
        labels
          ? formatMessage({ id: labels["sprinkler-point"].consumption })
          : "consumption"
      }
      text={formatNumber(sprinklerWaterflow)}
      unit="m<sup>3</sup>/h"
    />
  );
};

export const ConnectionPoint = ({
  labels,
  type,
  hasDripline = false,
  changeDripline = () => {},
  disabled,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <div className="pipeline-name">
        {type === "dripline-point"
          ? labels
            ? formatMessage({ id: labels["dripline-point"].type })
            : "dripline-point"
          : labels
          ? formatMessage({ id: labels["end-point"].type })
          : "end-point"}
      </div>
      <div className="pipeline-properties-item">
        <div className="pipeline-properties-row">
          <div className="small-text">
            {labels
              ? formatMessage({ id: labels["dripline-point"].toggle })
              : "dripline"}
          </div>
          <Toggle
            current={hasDripline}
            onChange={changeDripline}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export const WaterTapPoint = ({ labels, type }) => {
  const { formatMessage } = useIntl();
  return (
    <div>
      <div className="pipeline-name">
        {type === "water-tap-point"
          ? labels
            ? formatMessage({ id: labels["water-tap-point"].type })
            : "water-tap-point"
          : labels
          ? formatMessage({ id: labels["end-point"].type })
          : "end-point"}
      </div>
    </div>
  );
};

export const PipelineItem = ({ icon, label, text, unit, textInfo }) => {
  return (
    <div className="pipeline-properties-item icon">
      <div className="pipeline-properties-row">
        <div className="item-icon">
          {icon}
          <div className="small-text">{label}</div>
        </div>
        <div className="item-text-wrap">
          <div className="text-wrap">
            <span className="item-text">{text}</span>
            {unit && (
              <span
                className="small-text"
                dangerouslySetInnerHTML={{ __html: unit }}
              ></span>
            )}
          </div>
          {textInfo && (
            <div
              className="small-text text-info"
              dangerouslySetInnerHTML={{ __html: textInfo }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
};
