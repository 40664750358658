const { convertModelToFormData } = require("@dvsproj/ipat-core/apiUtils");

const checkResponse = (res: Response) => {
  if (!res.ok) {
    if ([401, 403].indexOf(res.status) >= 0) {
      throw new Error("login");
    } else {
      console.error("Response failed: " + res.status);
      throw new Error(`${res.status}: ${res.statusText}`);
    }
  }
};

const fetchToShop = async (
  url: string,
  method?: string,
  params?: any,
  config?: any
) => {
  let res: Response;
  if (method === "POST") {
    res = await postFormData(url, params, config);
  } else {
    res = await get(url, params, config);
  }

  checkResponse(res);

  return res;
};

/**
 * Wrapper for sending one line GET requests.
 * Utilizes browser fetch API
 * @param {*} url
 * @param {*} body
 * @param {*} defaultConfig
 * @throws throws error with statusText if response.ok returned false
 */
const get = async (url: string, params?: any, config: any = {}) => {
  const separator = url.search(/\?/) ? "&" : "?";

  const response = await fetch(
    url +
      (params && Object.keys(params).length > 0
        ? separator + new window.URLSearchParams(params)
        : ""),
    {
      ...config,
      credentials: config.credentials || "same-origin",
    }
  );

  checkResponse(response);

  return response;
};

/**
 * Wrapper for sending one line POST requests.
 * Utilizes browser fetch API
 * @param {*} url
 * @param {*} body
 * @param {*} defaultConfig
 * @throws throws error with statusText if response.ok returned false
 */
const post = async (url: string, body?: any, defaultConfig: any = {}) => {
  const config = {
    ...defaultConfig,
    credentials: defaultConfig.credentials || "same-origin",
    method: defaultConfig.method || "POST",
    headers: {
      ...(defaultConfig.headers || {}),
    },
    body: body != null ? body : undefined,
  };

  const response = await fetch(url + "", config);

  checkResponse(response);

  return response;
};

/**
 * Wrapper for sending one line POST requests with JSON.
 * Utilizes browser fetch API
 * @param {*} url
 * @param {*} body
 * @param {*} defaultConfig
 * @throws throws error with statusText if response.ok returned false
 */
const postJSON = (url: string, body?: any, defaultConfig: any = {}) => {
  const config = {
    ...defaultConfig,
    headers: {
      ...(defaultConfig.headers || {}),
      "Content-Type": "application/json",
    },
  };
  return post(url, JSON.stringify(body), config);
};

/**
 * Wrapper for sending one line POST requests with FormData.
 * Utilizes browser fetch API
 * @param {*} url
 * @param {*} body
 * @param {*} defaultConfig
 * @throws throws error with statusText if response.ok returned false
 */
const postFormData = async (
  url: string,
  body?: any,
  defaultConfig: any = {}
): Promise<Response> => {
  const config = {
    ...defaultConfig,
    headers: {
      ...(defaultConfig.headers || {}),
    },
  };

  return post(url, convertModelToFormData(body), config);
};

/**
 * Wrapper for sending one line DELETE requests.
 * Utilizes browser fetch API
 * @param {*} url
 * @param {*} params
 * @param {*} config
 * @throws throws error with statusText if response.ok returned false
 */
const deleteMethod = async (url: string, params?: any, config: any = {}) => {
  const separator = url.search(/\?/) ? "&" : "?";

  const response = await fetch(
    url +
      (params && Object.keys(params).length > 0
        ? separator + new window.URLSearchParams(params)
        : ""),
    {
      ...config,
      method: "DELETE",
      credentials: config.credentials || "same-origin",
    }
  );

  checkResponse(response);

  return response;
};

const CORS_CONFIG = {
  credentials: "omit",
  mode: "cors",
};

export type ApiConfig = {
  userinfoURL: string;
  planByIdURL: string;
  savePlanURL: string;
  removePlanURL: string;
  pricesURL: string;
  cartGeneratorURL: string;
  createPlanURL: string;
  planCheckedURL: string;
  planToCheckURL: string;
  wishListGeneratorURL: string;
  requestPlanDuplicationURL: string;
};

export type CartRequestItem = {
  articleNo: string;
  quantity: number;
};

declare global {
  interface Window {
    APP_CONFIG: any;
  }
}

export function urlDecorator(url: string) {
  let envs;
  if (typeof window === "undefined") {
    envs = {
      $SHOP_URL: process?.env?.REACT_APP_SHOP_URL,
      $CALC_BACKEND_URL: process?.env?.REACT_APP_CALC_BACKEND_URL,
      $SMART_GARDEN_URL: process?.env?.REACT_APP_SMART_GARDEN_URL,
    };
  } else {
    envs = {
      $SHOP_URL: window?.APP_CONFIG?.REACT_APP_SHOP_URL,
      $CALC_BACKEND_URL: window?.APP_CONFIG?.REACT_APP_CALC_BACKEND_URL,
      $SMART_GARDEN_URL: window?.APP_CONFIG?.REACT_APP_SMART_GARDEN_URL,
    };
  }

  let result = Object.keys(envs).reduce(
    (r, key) => r.replace(key, envs[key]),
    url
  );
  return result;
}

export function apiFactory({
  userinfoURL,
  planByIdURL,
  savePlanURL,
  removePlanURL,
  pricesURL,
  cartGeneratorURL,
  createPlanURL,
  planCheckedURL,
  planToCheckURL,
  wishListGeneratorURL,
  requestPlanDuplicationURL,
}: ApiConfig) {
  let csrf_token: string;
  return {
    getUserInfo: async () => {
      try {
        const res = await fetchToShop(userinfoURL);

        const { status, error_msg, response } = await res.json();

        if (status !== "ok") {
          throw new Error(
            error_msg
              ? error_msg
              : "An error occurred while getting user information"
          );
        }

        csrf_token = response.csrf_token;

        return response;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    createPlan: async (name: string, plan: any) => {
      const imageBase64 =
        plan.background && plan.background.src ? plan.background.src : null;

      const imageType = imageBase64?.match(/^data:([/\w]+);/);

      const res = await fetchToShop(createPlanURL, "POST", {
        name,
        image: imageBase64,
        image_type: imageType != null ? imageType[1] : null,
        csrf_token,
      });

      const { status, error_msg, response } = await res.json();

      if (status !== "ok") {
        throw new Error(
          error_msg ? error_msg : "An error occurred while creating plan"
        );
      }

      return response;
    },
    getPlanById: async (id?: string | null) => {
      if (id == null) {
        throw new Error("Invalid parameter value");
      }

      const res = await fetchToShop(planByIdURL, "GET", {
        ident: id,
        csrf_token,
      });
      const { status, error_msg, response } = await res.json();

      if (status !== "ok") {
        throw new Error(
          error_msg ? error_msg : "An error occurred while getting plan"
        );
      }

      const {
        data: dataStr,
        image,
        image_type,
        ident,
        name,
        status: planStatus,
      } = response;

      const data = JSON.parse(dataStr);
      if (planStatus != null) data.status = planStatus;

      return {
        name,
        data,
        image,
        image_type,
        ident,
      };
    },
    removePlanById: async (id: string) => {
      if (id == null) {
        throw new Error("Invalid parameter value");
      }

      const res = await fetchToShop(removePlanURL, "POST", {
        csrf_token: csrf_token,
        id: id,
      });
      const { status, error_msg, response } = await res.json();
      if (status === "ok" && response.status) {
        return true;
      } else {
        throw new Error(error_msg || "Error by remove plan");
      }
    },
    savePlan: async (
      id: string,
      name: string,
      planJSON: any,
      saveStatus: string
    ) => {
      if (planJSON == null) {
        throw new Error("Invalid parameter value");
      }

      const res = await fetchToShop(savePlanURL, "POST", {
        ident: id,
        name: name,
        status: saveStatus,
        planningTime: planJSON.planningTime,
        data: JSON.stringify(planJSON),
        csrf_token: csrf_token,
      });
      const { status, error_msg, response } = await res.json();
      if (status === "ok" && response.status) {
        return true;
      } else {
        if (error_msg) {
          throw new Error(error_msg);
        }

        return false;
      }
    },
    planChecked: async (id: string) => {
      const res = await fetchToShop(planCheckedURL, "POST", {
        ident: id,
        csrf_token: csrf_token,
      });

      const { status, error_msg, response } = await res.json();
      if (status === "ok" && response.status) {
        return true;
      } else {
        if (error_msg) {
          throw new Error(error_msg);
        }

        return false;
      }
    },
    planToCheck: async (id: string) => {
      const res = await fetchToShop(planToCheckURL, "POST", {
        ident: id,
        csrf_token: csrf_token,
      });
      const { status, error_msg, response } = await res.json();
      if (status === "ok" && response.status) {
        return true;
      } else {
        if (error_msg) {
          throw new Error(error_msg);
        }

        return false;
      }
    },
    getWishListUrl: async (
      id: string,
      articlesWithQuantity: CartRequestItem[]
    ) => {
      const res = await fetchToShop(wishListGeneratorURL, "POST", {
        ident: id,
        articles: articlesWithQuantity,
        csrf_token: csrf_token,
      });
      const { status, error_msg, response } = await res.json();

      if (status !== "ok") {
        throw new Error(error_msg ? error_msg : "An error");
      }
      return await response.redirect;
    },
    getPricesByArticleNumbers: async (articleNumbers: string[]) => {
      if (articleNumbers != null && articleNumbers.length > 0) {
        const res = await fetchToShop(pricesURL, "POST", {
          articles: articleNumbers,
          csrf_token: csrf_token,
        });
        const { status, error_msg, response } = await res.json();

        if (status !== "ok") {
          throw new Error(
            error_msg ? error_msg : "An error occurred while getting prices"
          );
        }

        return response.articles;
      }

      return [];
    },
    getCartUrl: async (id: string, articlesWithQuantity: CartRequestItem[]) => {
      const res = await fetchToShop(cartGeneratorURL, "POST", {
        ident: id,
        articles: articlesWithQuantity,
        csrf_token: csrf_token,
      });
      const { status, error_msg, response } = await res.json();

      if (status !== "ok") {
        throw new Error(
          error_msg ? error_msg : "An error occurred while getting prices"
        );
      }
      return await response.redirect;
    },
    duplicatePlan: async (planId: string) => {
      const res = await postJSON(
        requestPlanDuplicationURL,
        {
          plan_id: planId,
          csrf_token: csrf_token,
        },
        CORS_CONFIG
      );

      const result = await res.json();

      if (result.error) {
        throw new Error(
          result.error || "An error occured during plan duplication"
        );
      }

      return result;
    },
  };
}

export type CalcApiConfig = {
  sprinklerCalcURL: string;
  pipelineCalcURL: string;
  settingsFileUrl: string;
  generateImageURL: string;
  generatePdfURL: string;
  precipitationImageURL: string;
  updateSettingsURL: string;
  trenchingURL: string;
  savePlanURL: string;
  feedbackURL: string;
  requestRainBirdProductsURL: string;
  requestInstallerURL: string;
  saveStatsURL: string;
  assessmentURL: string;
};

const calcApiFactory = ({
  sprinklerCalcURL,
  pipelineCalcURL,
  settingsFileUrl,
  generateImageURL,
  generatePdfURL,
  precipitationImageURL,
  updateSettingsURL,
  trenchingURL,
  savePlanURL,
  feedbackURL,
  requestRainBirdProductsURL,
  requestInstallerURL,
  saveStatsURL,
  assessmentURL,
}: CalcApiConfig) => {
  return {
    calculateSprinklers: async (plan: any, recalcSprinklers: any) => {
      if (plan == null) {
        throw new Error("Invalid parameter value");
      }

      try {
        console.debug("Calculate sprinklers");
        const request = await postJSON(
          sprinklerCalcURL,
          { plan, recalcSprinklers },
          CORS_CONFIG
        );
        return request.json();
      } catch (err) {
        console.error("Error calculating sprinklers");
        console.error(err);
      }

      return undefined;
    },
    calculatePrecipitation: async (params: any) => {
      if (params == null) {
        throw new Error("Invalid parameter value");
      }

      try {
        console.debug("Calculate precipitation");
        const res = await postJSON(precipitationImageURL, params, CORS_CONFIG);

        const { status, error_msg, response } = await res.json();
        if (status === "ok") {
          return response;
        } else {
          if (error_msg) {
            throw new Error(error_msg);
          }

          return null;
        }
      } catch (err) {
        console.error("Error calculating sprinklers");
        console.error(err);
      }

      return undefined;
    },
    updateSettings: async (params: any) => {
      if (params == null) {
        throw new Error("Invalid parameter value");
      }

      try {
        console.debug("Update settings");
        const res = await postJSON(updateSettingsURL, params, CORS_CONFIG);

        const { status, error_msg, response } = await res.json();
        if (status === "ok") {
          return response;
        } else {
          if (error_msg) {
            throw new Error(error_msg);
          }

          return null;
        }
      } catch (err) {
        console.error("Error calculating sprinklers");
        console.error(err);
      }

      return undefined;
    },
    calculatePipeline: async (params: any) => {
      if (params == null) {
        throw new Error("Invalid parameter value");
      }

      try {
        console.debug("Calculate pipeline");
        const request = await postJSON(pipelineCalcURL, params, CORS_CONFIG);
        return request.json();
      } catch (err) {
        console.error("Error calculating pipeline");
        console.error(err);
      }

      return undefined;
    },
    fetchSettingsFile: async () => {
      const response = await fetch(settingsFileUrl);
      if (!response.ok) throw new Error("Failed to load settings");
      return response.json();
    },
    generateImage: async (
      planId: string | undefined,
      plan: any,
      layouts: any,
      textsVisibility: any
    ) => {
      console.debug("Generate image");
      if (plan == null) {
        throw new Error("Invalid parameter value");
      }

      const res = await postJSON(
        generateImageURL,
        {
          ident: planId,
          plan,
          layouts,
          textsVisibility,
        },
        CORS_CONFIG
      );

      const { status, error_msg, response } = await res.json();
      if (status === "ok") {
        return response;
      } else {
        if (error_msg) {
          throw new Error(error_msg);
        }

        return null;
      }
    },
    generatePdf: async (
      planId: string | undefined,
      plan: any,
      locale: string,
      action: string
    ) => {
      console.debug("Generate pdf");
      if (plan == null) {
        throw new Error("Invalid parameter value");
      }

      const res = await postJSON(
        generatePdfURL,
        {
          ident: planId,
          plan,
          url: window.location.href,
          locale,
          action,
        },
        CORS_CONFIG
      );

      const { status, error_msg, response } = await res.json();
      if (status === "ok") {
        return response;
      } else {
        if (error_msg) {
          throw new Error(error_msg);
        }

        return null;
      }
    },
    trenchingWays: async (plan: any, ways: any, trenchedPaths: any) => {
      console.debug("trenching ways..");

      if (plan == null || ways == null) {
        throw new Error("Invalid parameter value");
      }

      try {
        const request = await postJSON(
          trenchingURL,
          { plan, ways, trenchedPaths },
          CORS_CONFIG
        );
        return request.json();
      } catch (err) {
        console.error("Error calculating pipeline");
        console.error(err);
      }
      return undefined;
    },
    saveFeedback: async (
      ident: string,
      email: string,
      rate: any,
      category: string,
      description: string
    ) => {
      const res = await postJSON(
        feedbackURL,
        {
          ident,
          email,
          rate,
          category,
          description,
        },
        CORS_CONFIG
      );

      const { status, error_msg, response } = await res.json();
      if (status === "ok") {
        return response;
      } else {
        if (error_msg) {
          throw new Error(error_msg);
        }

        return null;
      }
    },
    requestRainBirdProducts: async (ident: string, email: string) => {
      const res = await postJSON(
        requestRainBirdProductsURL,
        {
          ident,
          email,
        },
        CORS_CONFIG
      );

      const { status, error_msg, response } = await res.json();
      if (status === "ok") {
        return response;
      } else {
        if (error_msg) {
          throw new Error(error_msg);
        }

        return null;
      }
    },
    requestInstaller: async (
      ident: string,
      email: string,
      zip: string,
      city: string,
      country: string
    ) => {
      const res = await postJSON(
        requestInstallerURL,
        {
          ident,
          email,
          zip,
          city,
          country,
        },
        CORS_CONFIG
      );

      const { status, error_msg, response } = await res.json();
      if (status === "ok") {
        return response;
      } else {
        if (error_msg) {
          throw new Error(error_msg);
        }

        return null;
      }
    },
    savePlan: async (
      id: string,
      name: string,
      planJSON: any,
      saveStatus: string,
      imageFile: any
    ) => {
      if (planJSON == null) {
        throw new Error("Invalid parameter value");
      }

      const form = new FormData();
      form.append("ident", id);
      form.append("name", name);
      form.append("status", saveStatus);
      form.append("planningTime", planJSON.planningTime);
      form.append("data", JSON.stringify(planJSON));
      if (imageFile != null) {
        form.append("image", imageFile);
      }

      try {
        const request = await post(savePlanURL, form, CORS_CONFIG);

        return request.json();
      } catch (err) {
        console.error("Error save plan");
        console.error(err);
      }
      return undefined;
    },
    saveStats: async (id: string, statsJSON: any) => {
      if (statsJSON == null) {
        throw new Error("Invalid parameter value");
      }

      try {
        await postJSON(
          saveStatsURL,
          {
            ident: id,
            data: statsJSON,
          },
          CORS_CONFIG
        );
      } catch (err) {
        console.error("Error save stats");
        console.error(err);
      }
      return undefined;
    },
    getAssessments: async (planId: string) => {
      if (planId == null) return;

      const url = `${assessmentURL}/${planId}`;
      const res = await get(url, null, CORS_CONFIG);
      const response = await res.json();

      const { assessment, annotations } = response;
      if (assessment == null && annotations == null) return null;

      return { assessment, annotations };
    },
    saveAssessments: async (planId: string, assessmentPayload: any) => {
      const url = `${assessmentURL}/${planId}`;

      const res = await postJSON(url, assessmentPayload, CORS_CONFIG);
      const result = await res.json();

      return result;
    },
    removeAssessments: async (planId: string) => {
      const url = `${assessmentURL}/${planId}`;

      await deleteMethod(url, null, CORS_CONFIG);

      return true;
    },
  };
};

export const calcApi = calcApiFactory({
  sprinklerCalcURL: urlDecorator("$CALC_BACKEND_URL/sprinklers"),
  pipelineCalcURL: urlDecorator("$CALC_BACKEND_URL/pipeline"),
  settingsFileUrl: urlDecorator("$CALC_BACKEND_URL/settings.json"),
  generateImageURL: urlDecorator("$CALC_BACKEND_URL/generatePNG"),
  generatePdfURL: urlDecorator("$CALC_BACKEND_URL/generatePDF"),
  precipitationImageURL: urlDecorator("$CALC_BACKEND_URL/precipitation"),
  updateSettingsURL: urlDecorator("$CALC_BACKEND_URL/update_settings"),
  trenchingURL: urlDecorator("$CALC_BACKEND_URL/trench"),
  savePlanURL: urlDecorator("$CALC_BACKEND_URL/savePlan"),
  feedbackURL: urlDecorator("$CALC_BACKEND_URL/feedback"),
  requestRainBirdProductsURL: urlDecorator(
    "$CALC_BACKEND_URL/rain-bird-products"
  ),
  requestInstallerURL: urlDecorator("$CALC_BACKEND_URL/installer"),
  saveStatsURL: urlDecorator("$CALC_BACKEND_URL/stats"),
  assessmentURL: urlDecorator("$CALC_BACKEND_URL/admin/assessment"),
});
