import React from "react";
import { observer } from "mobx-react";
import useStores from "../../hooks/useStores";

function Tooltip({
  showTooltip,
  hideTooltip,
  position = "right",
  tooltip,
  className,
  children,
  ...props
}) {
  const popupRef = React.useRef();

  React.useEffect(() => {
    const tooltipNode = popupRef.current;
    const mouseOver = (e) => {
      const tooltipCoords = e.target.getBoundingClientRect();

      showTooltip(tooltip, { position, coords: tooltipCoords });
    };
    if (tooltipNode) {
      tooltipNode.addEventListener("mouseenter", mouseOver);
      tooltipNode.addEventListener("mouseleave", hideTooltip);
    }

    return () => {
      tooltipNode.removeEventListener("mouseenter", mouseOver);
      tooltipNode.removeEventListener("mouseleave", hideTooltip);
    };
  });
  return (
    <React.Fragment>
      <div {...props} ref={popupRef}>
        {children}
      </div>
    </React.Fragment>
  );
}

let TooltipWithState = ({ ...props }) => {
  const { uiState } = useStores();
  return (
    <Tooltip
      showTooltip={uiState.showTooltip}
      hideTooltip={uiState.hideTooltip}
      {...props}
    />
  );
};

TooltipWithState = observer(TooltipWithState);
export default TooltipWithState;
