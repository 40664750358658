import { observable, extendObservable, action } from "mobx";
import { normalizeArticleNO } from "../../utils/bomUtils";

/**
 * Represents an BOM item object
 * @param {*} param0 - item json
 * @param {*} articleNO
 * @returns BOM item object
 */
const bomItemFactory = (
  {
    bomId,
    articleNO = null,
    pricePerUnit = null,
    quantity = 0,
    defaultQuantity = 0,
    name,
    description,
    qualityList = [],
  } = {},
  pricesDictionary
) => {
  const getPriceData = (articleNO) => {
    return pricesDictionary.get(normalizeArticleNO(articleNO));
  };

  const bomItem = observable({});

  const innerState = observable({
    quality: null,
    get defaultQuality() {
      return (
        bomItem.qualityList.find((e) => e.default) ?? bomItem.qualityList[0]
      );
    },
    get qualityIndex() {
      if (this.quality == null) {
        let currentQuality;

        if (articleNO != null) {
          currentQuality = bomItem.qualityList.find(
            (e) => e.articleNO === articleNO
          );
        }

        currentQuality = currentQuality ?? innerState.defaultQuality;
        this.quality = currentQuality.type;
      }
      return this.quality;
    },
    get currentQuality() {
      return bomItem.qualityList.find((e) => e.type === this.qualityIndex);
    },
  });

  extendObservable(bomItem, {
    get name() {
      return innerState.currentQuality ? innerState.currentQuality.name : name;
    },
    get description() {
      return innerState.currentQuality
        ? innerState.currentQuality.description
        : description;
    },
    get articleNO() {
      return innerState.currentQuality
        ? innerState.currentQuality.articleNO
        : articleNO;
    },
    get articleId() {
      return getPriceData(this.articleNO)?.articleId;
    },
    quantity: quantity,
    defaultQuantity: defaultQuantity ? defaultQuantity : quantity,
    get pricePerUnit() {
      return getPriceData(bomItem.articleNO)?.price ?? pricePerUnit ?? 0;
    },
  });

  extendObservable(bomItem, {
    get bomId() {
      return bomId;
    },
    get productLink() {
      return innerState.currentQuality
        ? innerState.currentQuality.productLink
        : null;
    },
    get minInstallationTime() {
      return innerState.currentQuality?.minInstallationTime ?? 0;
    },
    get maxInstallationTime() {
      return innerState.currentQuality?.maxInstallationTime ?? 0;
    },
    get quality() {
      return innerState.qualityIndex;
    },
    qualityList,
    get price() {
      return (this.quantity != null ? this.quantity : 0) * this.pricePerUnit;
    },
    get disabled() {
      return innerState.currentQuality ? false : true;
    },
    changeQuality: action((val) => {
      if (!bomItem.qualityList.some((q) => q.type === val)) {
        val = innerState.defaultQuality.type;
      }

      innerState.quality = val;
    }),
    changeQuantity: action((val) => {
      if (val >= 0 || val === undefined) {
        bomItem.quantity = val;
      }
    }),
  });

  extendObservable(bomItem, {
    get toJSON() {
      const {
        bomId,
        quantity,
        defaultQuantity,
        articleNO,
        name,
        description,
        pricePerUnit,
        productLink,
      } = this;
      let result = {
        bomId,
        quantity,
        defaultQuantity,
        articleNO,
        name,
        description,
        pricePerUnit,
        productLink,
      };
      return result;
    },
  });

  return bomItem;
};

export default bomItemFactory;
