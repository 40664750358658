import { v4 as uuid } from "uuid";
import { observable, action, extendObservable } from "mobx";

export const PRESSURE_TUBING_COLOR = "#006CFF";

/**
 * Factory method for a pipeline representation
 * @param param -
 * Contain the getters:
 * - pointIds, points - pipeline points
 * - hasSprinklerPoint, hasDriplinePoint -
 * - waterQuantity - water quantity value
 */
const planPipelineFactory = (plan, settingsState = {}) =>
  action(({ id, pointIds = [], lines = [] }) => {
    const innerState = observable({
      disabled: null,
    });

    const line = observable({
      id: id || "pipeline-" + uuid(),
      type: "pipeline",
      pointIds: pointIds.filter((v, i, a) => a.indexOf(v) === i),
      lines,
      defaultPoints: null,
      set disabled(disabled) {
        innerState.disabled = disabled;
      },
      get disabled() {
        if (innerState.disabled == null) {
          let pipelineDisabled = true;
          line.lines.forEach(
            ({ disabled }) => (pipelineDisabled = pipelineDisabled && disabled)
          );
          line.points.forEach(({ disabled, isConnectionPoint }) => {
            if (!isConnectionPoint)
              pipelineDisabled = pipelineDisabled && disabled;
          });

          return pipelineDisabled;
        }

        return innerState.disabled;
      },
    });

    extendObservable(line, {
      get points() {
        const points = this.pointIds
          .map((id) => {
            const pointById = plan.pipePoints.find((p) => p.id === id);
            return pointById;
          })
          .filter((p) => p != null);
        return line.defaultPoints || points;
      },
      get sprinklers() {
        return this.points
          .filter(
            ({ isSprinklerPoint, isRzwsPoint, isRaisedBedPoint }) =>
              isSprinklerPoint || isRzwsPoint || isRaisedBedPoint
          )
          .reduce((unique, p) => {
            const e = plan.elements.find((s) => s.id === p.parentId);
            if (e != null && unique.find(({ id }) => id === e.id) == null) {
              unique.push(e);
            }
            return unique;
          }, []);
      },
      get waterQuantity() {
        let result = 0;
        if (line.points && line.points.length > 0) {
          result = line.points
            .filter(
              (p) =>
                p &&
                (p.pointType === "sprinkler-point" ||
                  p.pointType === "rzws-point" ||
                  p.pointType === "raised-bed-point")
            )
            .map((p) => {
              const arr =
                p.pointType === "sprinkler-point"
                  ? plan.sprinklers
                  : p.pointType === "rzws-point"
                  ? plan.rzws
                  : plan.raisedBeds; //"raised-bed-point"
              return arr?.find((s) => s.x === p.x && s.y === p.y);
            })
            .reduce((acc, point) => {
              return point && point.waterflow ? acc + point.waterflow : acc;
            }, 0);
        }
        return result;
      },
      get shapeLimit() {
        const waterSupply = plan
          ? plan.elements.find(
              (element) =>
                element.type === "system-element" &&
                element.systemType === "water-supply"
            )
          : null;
        return waterSupply && waterSupply.waterQuantity
          ? waterSupply.waterQuantity * 1000
          : null;
      },
      get colors() {
        const l = line.lines.filter((p) => p.isStartLine);
        return l && l.length > 0 ? l.map((p) => p.color) : undefined;
      },
      get color() {
        const { color } = this.lineOptions;
        return color;
      },
      get lineOptions() {
        let options = {
          color:
            line.colors && line.colors.length > 0
              ? line.colors[0] //line.hasError ? "#00f" : line.colors[0]
              : "#9EA1A2",
          strokeWidth: 6,
        };

        if (this.lineType === "pressure-tubing") {
          options = {
            color: PRESSURE_TUBING_COLOR,
            strokeWidth: 3,
          };
        }

        return options;
      },
      get totalLength() {
        let total = 0;
        line.lines.forEach(({ length }) => {
          total += length;
        });
        return total;
      },
      get flowerArea() {
        let dripline = line.points.find(
          ({ isDriplinePoint }) => isDriplinePoint
        );
        return dripline ? dripline.driplineArea.size : undefined;
      },
    });

    extendObservable(line, {
      get hasSprinklerPoint() {
        return this.points.findIndex((p) => p.isSprinklerPoint) >= 0;
      },
      get driplinePoint() {
        return this.points.find(({ isDriplinePoint }) => isDriplinePoint);
      },
      get hasDriplinePoint() {
        return this.driplinePoint != null;
      },
      get hasWaterTapPoint() {
        return this.points.findIndex((p) => p.isWaterTapPoint) >= 0;
      },
      get hasRzwsPoint() {
        return this.points.findIndex((p) => p.isRzwsPoint) >= 0;
      },
      get hasRaisedBedPoint() {
        return this.points.findIndex((p) => p.isRaisedBedPoint) >= 0;
      },
      get hasStartPoint() {
        return this.points.findIndex((p) => p.isStartPoint) >= 0;
      },
      get isTubingPipe() {
        return this.lineType === "pressure-tubing";
      },
      get lineType() {
        const isSystemElement =
          line.points.length === 1 && line.points[0].isSystemElementPoint;
        const isAloneWaterTap =
          isSystemElement && line.points[0].isWaterTapPoint;

        const isPressureTubing =
          this.lines.find(
            (l) =>
              [l.startPoint?.pointType, l.endPoint?.pointType].indexOf(
                "water-supply"
              ) >= 0
          ) != null ||
          this.points.find(({ isWaterTapPoint }) => isWaterTapPoint) != null;

        let lineType = "sprinkler";

        if (isSystemElement && !isAloneWaterTap) {
          lineType = "system-element";
        } else if (this.hasDriplinePoint) {
          lineType = "dripline";
        } else if (this.hasRzwsPoint) {
          lineType = "rzws-line";
        } else if (this.hasRaisedBedPoint) {
          lineType = "raised-bed-line";
        } else if (isPressureTubing || isAloneWaterTap) {
          lineType = "pressure-tubing";
        }
        return lineType;
      },
    });

    // errors
    extendObservable(line, {
      get valveboxIds() {
        const resultSet = new Set();
        line.lines.forEach(({ startPoint, endPoint }) => {
          [startPoint, endPoint]
            .filter((p) => p?.isStartPoint)
            .forEach((el) => resultSet.add(el.parentId ?? el.sprinkler?.id));
        });

        return [...resultSet];
      },
      get numberOfConnectionToStart() {
        const pointCount = {};
        line.lines.forEach(({ startPoint, endPoint }) => {
          const valveboxes = [startPoint, endPoint].filter(
            (p) => p?.isStartPoint
          );
          if (valveboxes.length === 0) {
            return;
          }

          for (let point of valveboxes) {
            if (point.id in pointCount) {
              pointCount[point.id]++;
            } else {
              pointCount[point.id] = 1;
            }
          }
        });

        return line.lineType === "pressure-tubing"
          ? Object.values(pointCount).reduce(
              (acc, value) => (acc > value ? acc : value),
              0
            )
          : Object.values(pointCount).reduce((acc, value) => acc + value, 0);
      },
      get errors() {
        let errors = [];
        const isSystemElement =
          line.points.length === 1 && line.points[0].isSystemElementPoint;
        if (isSystemElement) return errors;

        const stepLabels = settingsState ? settingsState.texts.steps : null;

        // pipeline is not enough pressure
        if (line.shapeLimit && line.shapeLimit < line.waterQuantity) {
          errors.push(
            stepLabels ? stepLabels.pipeline.error.waterQuantityError : "Error"
          );
        }

        const numberOfConnectionsToWaterSupply = line.lines.filter(
          ({ startPoint, endPoint }) =>
            startPoint?.isWaterSupplyPoint || endPoint?.isWaterSupplyPoint
        ).length;

        // the pipeline is not connected to the valvebox
        if (line.numberOfConnectionToStart === 0) {
          errors.push(
            stepLabels ? stepLabels.pipeline.error.noConnectionToStart : "Error"
          );
        }

        // pipeline has several connections to the valvebox
        if (line.numberOfConnectionToStart > 1) {
          errors.push(
            stepLabels
              ? stepLabels.pipeline.error.multipleConnectionToStart
              : "Error"
          );
        }

        if (line.lineType === "pressure-tubing") {
          if (numberOfConnectionsToWaterSupply === 0) {
            errors.push(
              stepLabels
                ? stepLabels.pipeline.error.noConnectionToWaterSupply
                : "Error"
            );
          }
        }

        return errors;
      },
      get hasError() {
        return line.errors.length > 0;
      },
    });

    // action
    extendObservable(line, {
      hidden: false,
      onDisable: action(() => {
        innerState.disabled = !line.disabled;

        line.lines.forEach(({ onDisable }) => onDisable(innerState.disabled));
        line.points.forEach(({ onDisable }) => {
          if (onDisable != null) onDisable(innerState.disabled);
        });
      }),
      setHidden: action((val) => {
        line.hidden = val;
      }),
    });

    return line;
  });

export default planPipelineFactory;
