import React from "react";

class FunctionalKeysObserver extends EventTarget {
  constructor() {
    super();
    this.keyDownListener = this.keyDownListener.bind(this);
    this.keyUpListener = this.keyUpListener.bind(this);

    if (typeof window !== "undefined") {
      window?.addEventListener("keydown", this.keyDownListener);
      window?.addEventListener("keyup", this.keyUpListener);
    }
  }

  keyDownListener(event) {
    const eventKey = event?.key?.toLowerCase();
    switch (eventKey) {
      case "alt":
        this.dispatchEvent(new Event("altKeyDown"));
        break;
      case "shift":
        this.dispatchEvent(new Event("shiftKeyDown"));
        break;
      default:
        break;
    }
  }

  keyUpListener(event) {
    const eventKey = event?.key?.toLowerCase();
    switch (eventKey) {
      case "alt":
        this.dispatchEvent(new Event("altKeyUp"));
        break;
      case "shift":
        this.dispatchEvent(new Event("shiftKeyUp"));
        break;
      default:
        break;
    }
  }

  removeEventListeners() {
    if (typeof window !== "undefined") {
      window?.removeEventListener("keydown", this.handleKeyDown);
      window?.removeEventListener("keyup", this.handleKeyUp);
    }
  }
}

const fKeysObserver = new FunctionalKeysObserver();
const Context = React.createContext({
  pressed: fKeysObserver.isShiftKeyPressed || fKeysObserver.isAltKeyPressed,
});

function FunctionalKeysObserverComponent({ children }) {
  const [isShiftKeyPressed, setShiftKeyPressed] = React.useState(
    fKeysObserver.isShiftKeyPressed
  );
  const [isAltKeyPressed, setAltKeyPressed] = React.useState(
    fKeysObserver.isAltKeyPressed
  );

  const listener = (event) => {
    switch (event?.type) {
      case "shiftKeyDown": {
        setShiftKeyPressed(true);
        break;
      }
      case "shiftKeyUp": {
        setShiftKeyPressed(false);
        break;
      }
      case "altKeyDown": {
        setAltKeyPressed(true);
        break;
      }
      case "altKeyUp": {
        setAltKeyPressed(false);
        break;
      }
      default:
        break;
    }
  };

  React.useEffect(() => {
    fKeysObserver.addEventListener("altKeyDown", listener);
    fKeysObserver.addEventListener("altKeyUp", listener);
    fKeysObserver.addEventListener("shiftKeyDown", listener);
    fKeysObserver.addEventListener("shiftKeyUp", listener);
    return () => {
      fKeysObserver.removeEventListener("altKeyDown", listener);
      fKeysObserver.removeEventListener("altKeyUp", listener);
      fKeysObserver.removeEventListener("shiftKeyDown", listener);
      fKeysObserver.removeEventListener("shiftKeyUp", listener);
    };
  }, []);

  return (
    <Context.Provider value={isShiftKeyPressed || isAltKeyPressed}>
      {children}
    </Context.Provider>
  );
}

function useFunctionalKeysObserver() {
  const pressed = React.useContext(Context);
  return pressed ?? false;
}

export { useFunctionalKeysObserver, FunctionalKeysObserverComponent };
