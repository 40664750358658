import React from "react";

import { observer } from "mobx-react";

import SystemElement from "./SystemElement";
import SensorElement from "./SensorElement";

import ScaleInputTool from "./scale/ScaleInputTool";

import DrawingTool from "./simpleComponents/DrawingTool";
import Areas from "./areas/Areas";
import Pipes from "./pipes/Pipes";

import PlanDraggableContainer from "./PlanDraggableContainer";
import { getZoomByScale, getSvgPoint } from "@dvsproj/ipat-core/planUtils";
import {
  Background,
  Grid,
  Overlay,
  ElementsWrapper,
} from "./simpleComponents/SimpleComponents";
import Sprinklers from "./sprinklers/Sprinklers";
import Coverage from "./coverage/Coverage";
import useStores from "../hooks/useStores";

const IrrigationPlan = observer(
  class extends React.Component {
    state = {
      isPinch: false,
    };

    zoom = {
      cursor: { x: 0, y: 0 },
      zoomTouches: [],
      lastTouchZoom: 0,
      prevScale: 0,
      getDistance: (a, b) => {
        return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
      },
      touchCoords: (touch) => {
        let headerWidth = document.querySelector("header").offsetHeight;
        const svgP = getSvgPoint(touch, this.c.querySelector("svg"));
        return {
          x: touch.pageX,
          y: touch.pageY - headerWidth,
          svg: svgP,
        };
      },
      displayTouch: (touch, fill = "red", radius = 10, className = "") => {
        this.c.querySelector(
          "svg"
        ).innerHTML += `<circle class="${className}" transform="translate(${touch.svg.x} ${touch.svg.y})" x="0" y="0" r="${radius}" fill="${fill}"/>`;
      },
      removeTouches: (className) => {
        this.c.querySelectorAll("." + className).forEach((e) => e.remove());
      },
      mobileLog: (text) => {
        document.querySelector("header .user span").innerHTML = text;
      },
      onTouchStart: (e) => {
        const { touchCoords } = this.zoom;

        let touches = e.touches;
        if (touches.length === 2) {
          for (let i = 0; i <= touches.length - 1; i++) {
            const touch = touchCoords(touches[i]);

            this.zoom.zoomTouches.push(touch);
            // this.zoom.displayTouch(touch, "blue", 20, "start");
          }
          this.zoom.prevScale = 1 / this.props.zoomDelta;
        }
      },
      onTouchMove: (e) => {
        e.preventDefault();

        const { getDistance, touchCoords } = this.zoom;

        let changedTouches = [];
        this.zoom.removeTouches("changed");
        for (let i = 0; i <= e.touches.length - 1; i++) {
          const touch = touchCoords(e.touches[i]);
          changedTouches.push(touch);
          // this.zoom.displayTouch(touch, "red", 20, "changed");
        }

        const { zoomTouches, prevScale } = this.zoom;

        if (zoomTouches.length === 2 && changedTouches.length === 2) {
          this.setState({ isPinch: true });

          const { zoomTo, zoomStep, width } = this.props;

          const startDistance = getDistance(zoomTouches[0], zoomTouches[1]);
          const endDistance = getDistance(changedTouches[0], changedTouches[1]);
          let scale = endDistance / startDistance;
          if (scale !== 1) {
            let zoom = getZoomByScale(scale * prevScale, zoomStep, width);
            zoomTo(zoom, {
              x: (zoomTouches[0].x + zoomTouches[1].x) / 2,
              y: (zoomTouches[0].y + zoomTouches[1].y) / 2,
            });
          }
        }
      },
      onTouchEnd: (e) => {
        this.zoom.zoomTouches = [];
        this.zoom.lastTouchZoom = 0;
        this.setState({ isPinch: false });
        // this.zoom.removeTouches("changed");
        // this.zoom.removeTouches("start");
      },
      deltaZoom: ({ x, y }) => {
        const { zoomStep, width, stepDelta } = this.props;
        let k = stepDelta * (zoomStep / width);
        return { x: x * k, y: y * k };
      },
      onMouseMove: (e) => {
        this.zoom.cursor = { x: e.offsetX, y: e.offsetY };

        // e.preventDefault();
      },
      onWheel: (e) => {
        e.preventDefault();

        const { zoomIn, zoomOut } = this.props;
        if (e.deltaY > 0) {
          zoomOut(this.zoom.cursor);
        } else {
          zoomIn(this.zoom.cursor);
        }
      },
      onKey: (e) => {
        const { zoomIn, zoomOut } = this.props;
        if (e.shiftKey) {
          switch (e.code) {
            case "NumpadSubtract":
            case "Minus":
              zoomOut(this.zoom.cursor);

              break;
            case "NumpadAdd":
            case "Equal":
              zoomIn(this.zoom.cursor);

              break;
            default:
              break;
          }
        }
      },
    };
    hasInput = (e) => {
      return (
        e.target instanceof HTMLInputElement ||
        e.target instanceof HTMLTextAreaElement
      );
    };
    onKeyDown = (e) => {
      if (e.key === "Shift" || e.shiftKey) {
        this.props.setQuickZoom(true);
      }
      if (!this.hasInput(e) && e.code === "Space") {
        e.preventDefault();
        this.props.setSelectedOtherTool("draggable");
      }
    };
    onKeyUp = (e) => {
      if (e.key === "Shift") {
        this.props.setQuickZoom(false);
      }

      switch (e.code) {
        case "Space":
          if (!this.hasInput(e)) {
            e.preventDefault();
            this.props.setSelectedOtherTool();
          }
          break;
        case "Escape":
          this.props.clearSelectedElement();
          break;
        case "Delete":
        case "Backspace":
          if (!this.hasInput(e)) {
            this.props.onRemoveElement();
          }
          break;
        default:
          this.zoom.onKey(e);
          break;
      }
    };

    resize = () => {
      const { setSize } = this.props;
      if (this.c) {
        setSize(this.c.clientWidth, this.c.clientHeight);
      }
    };

    clearSelectedEl = (e) => {
      const { plan, clearSelectedElement } = this.props;

      if (this.c && plan) {
        var hasContains = false;
        const elements = this.c.querySelectorAll("g.element");

        elements.forEach((element) => {
          if (!hasContains && element.contains(e.target)) {
            hasContains = true;
          }
        });
        if (!hasContains) {
          clearSelectedElement();
        }
      }
    };

    onMouseDown = (e) => {
      const { clientX, clientY } = e;
      this.mouseDown = { clientX, clientY };
    };

    onMouseUp = (e) => {
      const { clientX, clientY } = e;
      if (
        this.mouseDown &&
        Math.abs(clientX - this.mouseDown.clientX) < 5 &&
        Math.abs(clientY - this.mouseDown.clientY) < 5
      ) {
        this.clearSelectedEl(e);
      }
      this.mouseDown = undefined;
    };

    componentDidMount() {
      window.addEventListener("resize", () => {
        setTimeout(() => this.resize(), 20);
      });
      setTimeout(() => this.resize(), 1);

      window.addEventListener("keydown", this.onKeyDown);
      window.addEventListener("keyup", this.onKeyUp);
      this.c.addEventListener("mousemove", this.zoom.onMouseMove);
      this.c.addEventListener("mousedown", this.onMouseDown);
      this.c.addEventListener("mouseup", this.onMouseUp);
      this.c.addEventListener("touchstart", this.zoom.onTouchStart, {
        passive: false,
      });
      this.c.addEventListener("touchend", this.zoom.onTouchEnd, {
        passive: false,
      });
      this.c.addEventListener("touchcancel", this.zoom.onTouchEnd, {
        passive: false,
      });
      this.c.addEventListener("touchmove", this.zoom.onTouchMove, {
        passive: false,
      });
      this.c.addEventListener("wheel", this.zoom.onWheel, { passive: false });
    }

    componentDidUpdate(props) {
      if (props.plan == null && this.props.plan != null) {
        this.resize();
      }
    }

    componentWillUnmount() {
      window.removeEventListener("keydown", this.onKeyDown);
      window.removeEventListener("keyup", this.onKeyUp);
      this.c.removeEventListener("keydown", this.onRemoveElement);
      this.c.removeEventListener("mousemove", this.onMouseMove);
      this.c.removeEventListener("mousedown", this.onMouseDown);
      this.c.removeEventListener("mouseup", this.onMouseUp);
      this.c.removeEventListener("touchstart", this.zoom.onTouchStart, {
        passive: false,
      });
      this.c.removeEventListener("touchend", this.zoom.onTouchEnd, {
        passive: false,
      });
      this.c.removeEventListener("touchcancel", this.zoom.onTouchEnd, {
        passive: false,
      });
      this.c.removeEventListener("touchmove", this.zoom.onTouchMove, {
        passive: false,
      });
      this.c.removeEventListener("wheel", this.zoom.onWheel, {
        passive: false,
      });
    }

    render() {
      const {
        width,
        height,
        viewbox,
        plan,
        showGrid,
        grid,
        draggable,
        scaleActive,
        rulerActive,
        selectedTool,
        zoomDelta,
        stepIdx,
        stepIdxByName,
        offsets,
        setMove,
        savePlanPosition,
        cursor,
        sortBySelectedElement,
      } = this.props;
      const defaultViewbox = {
        x: 0,
        y: 0,
        w: width ? width : 0,
        h: height ? height : 0,
      };

      const drawingTools = [
        "polygon",
        "rectangle",
        "circle",
        "sprinkler-circle",
        "sprinkler-rect",
        "water-supply",
        "controller",
        "valve-box",
        "sensor",
        "water-tap-point",
        "fertilizer",
        "air-compressor",
        "water-filter",
        "water-meter",
        "rzws",
        "raised-bed",
        "combi-box",
      ];
      const selectedDrawingTool = drawingTools.indexOf(selectedTool) > -1;

      let systemElements = [];
      // generate system
      if (plan && plan.systemElements) {
        sortBySelectedElement(plan.systemElements).forEach((element, i) => {
          systemElements.push(
            <SystemElement key={element.id} element={element} />
          );
        });
      }

      // generate sensor
      let sensors = [];
      if (plan && plan.sensors) {
        plan.sensors.forEach((element, i) => {
          sensors.push(<SensorElement key={element.id} element={element} />);
        });
      }

      const planDraggable = draggable && !this.state.isPinch; /* && false;*/

      return (
        <div
          ref={(c) => (this.c = c)}
          className={`irrigation-plan ${
            planDraggable ? "draggable background-move" : ""
          }`}
          style={{ cursor: cursor ? cursor : "auto" }}
        >
          <PlanDraggableContainer
            className="plan-container"
            x={offsets.x}
            y={offsets.y}
            move={(obj) => {
              setMove(obj.x - obj.deltaX, obj.y - obj.deltaY);
              savePlanPosition();
            }}
            draggable={planDraggable}
          >
            {plan != null && (
              <>
                <Background
                  viewbox={viewbox ?? defaultViewbox}
                  width={plan.width}
                  height={plan.height}
                  background={plan.background}
                  backgroundSize={plan.backgroundScaledSize}
                />
                <Overlay
                  viewbox={defaultViewbox}
                  width={width}
                  height={height}
                  opacity={plan.opacity}
                />
                {stepIdx === stepIdxByName("sprinklers") ? (
                  <Coverage
                    type="sprinkler"
                    viewbox={viewbox ?? defaultViewbox}
                  />
                ) : null}
                {stepIdx === stepIdxByName("sensor") ? (
                  <Coverage
                    type="sensor"
                    viewbox={viewbox ?? defaultViewbox}
                    opacity={0.7}
                  />
                ) : null}
                {grid && showGrid ? (
                  <Grid
                    viewbox={viewbox ?? defaultViewbox}
                    width={plan.width}
                    height={plan.height}
                    grid={grid}
                  />
                ) : null}
                {scaleActive ? (
                  <ScaleInputTool
                    viewbox={viewbox ?? defaultViewbox}
                    width={plan.width}
                    height={plan.height}
                    setScaleLength={plan.setScaleLength}
                    draggable={planDraggable}
                    zoomDelta={zoomDelta}
                  />
                ) : null}
                {rulerActive ? (
                  <ScaleInputTool
                    viewbox={viewbox ?? defaultViewbox}
                    width={plan.width}
                    height={plan.height}
                    setScaleLength={plan.setScaleLength}
                    draggable={planDraggable}
                    zoomDelta={zoomDelta}
                    scale={plan.scale}
                    hasLengthText={true}
                  />
                ) : null}
                {plan.areas?.length && stepIdx >= stepIdxByName("areas") ? (
                  <Areas
                    viewbox={viewbox ?? defaultViewbox}
                    width={plan.width}
                    height={plan.height}
                    areas={sortBySelectedElement(plan.areas)}
                  />
                ) : null}
                {stepIdx >= stepIdxByName("sprinklers") ? (
                  <Sprinklers
                    viewbox={viewbox ?? defaultViewbox}
                    width={plan.width}
                    height={plan.height}
                    sprinklers={sortBySelectedElement(plan.sprinklers)}
                    rzws={plan.rzws}
                    raisedBeds={plan.raisedBeds}
                  />
                ) : null}
                {stepIdx >= stepIdxByName("system-elements") ? (
                  <ElementsWrapper
                    viewbox={viewbox ?? defaultViewbox}
                    width={plan.width}
                    height={plan.height}
                  >
                    {systemElements}
                  </ElementsWrapper>
                ) : null}
                {stepIdx >= stepIdxByName("pipeline") ? (
                  <>
                    {
                      <Pipes
                        viewbox={viewbox ?? defaultViewbox}
                        width={plan.width}
                        height={plan.height}
                        pipelines={sortBySelectedElement(plan.pipelines, true)}
                        pipesModifiedAt={plan.pipesModifiedAt}
                        trenches={plan.trenches}
                      />
                    }
                    {/*<Trenches
                        viewbox={viewbox ?? defaultViewbox}
                        width={plan.width}
                        height={plan.height}
                        //calculatedTrenches={plan.trenches}
                        pipelines={plan.pipelines}
                        tubings={plan.tubings}
                        irrigationValveCable={plan.irrigationValveCable}
                        waterMeterCable={plan.waterMeterCable}
                      />*/}
                  </>
                ) : null}
                {stepIdx >= stepIdxByName("sensor") ? (
                  <ElementsWrapper
                    viewbox={viewbox ?? defaultViewbox}
                    width={plan.width}
                    height={plan.height}
                    style={{ zIndex: 102 }}
                  >
                    {sensors}
                  </ElementsWrapper>
                ) : null}
                {selectedDrawingTool ? (
                  <DrawingTool
                    viewbox={viewbox ?? defaultViewbox}
                    width={plan.width}
                    height={plan.height}
                  />
                ) : null}
              </>
            )}
          </PlanDraggableContainer>
        </div>
      );
    }
  }
);

let IrrigationPlanWithState = () => {
  const { uiState } = useStores();

  return (
    <IrrigationPlan
      width={uiState.containerWidth}
      height={uiState.containerHeight}
      viewbox={uiState.viewbox}
      plan={uiState.plan}
      draggable={
        uiState.selectedOtherTool === "draggable" ||
        uiState.selectedTool === "select"
      }
      setSelectedOtherTool={uiState.setSelectedOtherTool}
      selectedTool={uiState.selectedTool}
      setSize={uiState.setContainerSize}
      setMove={uiState.setMove}
      savePlanPosition={uiState.savePlanPosition}
      zoom={uiState.zoomState.zoom}
      zoomIn={(center) => {
        const { zoom, stepDelta, centerZoom } = uiState.zoomState;
        centerZoom(zoom - stepDelta, center);
        uiState.savePlanPosition();
      }}
      zoomOut={(center) => {
        const { zoom, stepDelta, centerZoom } = uiState.zoomState;
        centerZoom(zoom + stepDelta, center);
        uiState.savePlanPosition();
      }}
      zoomDelta={uiState.zoomState.zoomDelta}
      zoomStep={uiState.zoomState.zoomStep}
      setQuickZoom={uiState.zoomState.setQuickZoom}
      zoomTo={() => {
        uiState.zoomState.centerZoom();
        uiState.savePlanPosition();
      }}
      stepDelta={uiState.zoomState.stepDelta}
      showGrid={uiState.showGrid}
      grid={uiState.grid}
      offsets={uiState.offsets}
      scaleActive={uiState.selectedTool && uiState.selectedTool === "scale"}
      rulerActive={uiState.selectedTool && uiState.selectedTool === "ruler"}
      stepIdx={uiState.stepIdx}
      clearSelectedElement={uiState.clearSelectedElement}
      stepIdxByName={uiState.stepIdxByName}
      onRemoveElement={uiState.onRemoveElement}
      cursor={uiState.cursor}
      sortBySelectedElement={(elements, hasPipleine = false) => {
        const { hoveredElementId, selectedElementId } = uiState;
        const otherSort = (a, b) => {
          if (a.id === selectedElementId || a.id === hoveredElementId) return 0;
          if (b.id === selectedElementId || b.id === hoveredElementId)
            return -1;
          return 1;
        };

        const hasSelected = (arr) => {
          let res = false;
          let i = 0;
          while (!res && i < arr.length) {
            if (
              arr[i].id === selectedElementId ||
              arr[i].id === hoveredElementId
            )
              res = true;
            i++;
          }
          return res;
        };

        const pipelinesSort = (a, b) => {
          if (hasSelected(a.lines) || hasSelected(a.points)) {
            return 1;
          }

          if (hasSelected(b.lines) || hasSelected(b.points)) {
            return -1;
          }

          return 0;
        };

        return elements && elements.length > 0
          ? elements.slice().sort(hasPipleine ? pipelinesSort : otherSort)
          : elements;
      }}
    />
  );
};

IrrigationPlanWithState = observer(IrrigationPlanWithState);

export default IrrigationPlanWithState;
