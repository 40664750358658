import { action, extendObservable, observable, reaction } from "mobx";
import { throttle } from "lodash";

import {
  generateGrid,
  sizeInPixelByMeters,
} from "@dvsproj/ipat-core/planUtils";
import { adjustmentPoint } from "@dvsproj/ipat-core/areaUtils";

import planFactory from "../planFactory";
import { zoomStateFactory } from "../zoomStateFactory";
import { calcApi, urlDecorator, apiFactory } from "../../utils/api";
import injectStepsAPI from "./injectStepsAPI";
import injectDialogApi from "./injectDialogApi";
import injectReactions from "./injectReactions";
import { sleep } from "@dvsproj/ipat-core/helpers";
import { formatDate } from "@dvsproj/ipat-core/formatter";
import injectPlanSaveApi from "./injectPlanSaveApi";
import { debounce } from "lodash";
import injectTooltipApi from "./injectTooltipApi";
import injectExportApi from "./injectExportApi";
import {
  getAllArticles,
  normalizeArticleNO,
  convertBomListByBoxes,
} from "../../utils/bomUtils";
import injectCoverageAPI from "../planFactory/injectCoverageAPI";
import injectTrenchingAPI from "../planFactory/injectTrenchingAPI";
import injectMarketingApi from "./injectMarketingApi";
import userStateFactory from "../userStateFactory";
import bomItemFactory from "../types/bomItemFactory";
import { planVersion } from "../planFactory/planVersion";

/**
 * Creates a store that represents
 * the current state of the UI.
 *
 * Returned object contains
 * - the plan with the history of plan usage
 * - selected tool
 * - selected element
 * - zoom
 * - step specification: validation and calculation, current step
 * - grid
 * @param {*} settings - a js configuration that is used
 * for all types of calculations and algorithms,
 * alongside with lables for UI
 */
const uiStateFactory = (defaultSettingsState, intl) => {
  const state = {
    drawingPipeLengthInPx: 0,
  };
  const settingsState = observable(defaultSettingsState);

  const hasDev = window.APP_CONFIG.NODE_ENV === "development";

  const restApiURL = settingsState
    ? urlDecorator(settingsState.links.restApi)
    : "/";

  const session = document.cookie
    .split("; ")
    .map((c) => c.split("="))
    .find(([k]) => k === "JTLSHOP")?.[1];

  // we don't encode url parameters
  const restApi = new Proxy(
    apiFactory({
      userinfoURL: `${restApiURL}?action=userinfo&session=${session}`,
      planByIdURL: `${restApiURL}?action=getPlan&session=${session}`,
      savePlanURL: `${restApiURL}?action=updatePlan&session=${session}`,
      removePlanURL: `${restApiURL}?action=deletePlan&session=${session}`,
      createPlanURL: `${restApiURL}?action=createPlan&session=${session}`,
      pricesURL: `${restApiURL}?action=getPrices&session=${session}`,
      cartGeneratorURL: `${restApiURL}?action=createCart&session=${session}`,
      planCheckedURL: `${restApiURL}?action=mark_as_checked&session=${session}`,
      planToCheckURL: `${restApiURL}?action=check_plan&session=${session}`,
      wishListGeneratorURL: `${restApiURL}?action=createWishlist&session=${session}`,
      requestPlanDuplicationURL: urlDecorator(
        `$CALC_BACKEND_URL/duplicatePlan?session=${session}`
      ),
      hasDev,
    }),
    {
      get(target, prop) {
        if (prop in target) {
          const fn = target[prop];
          return async (...args) => {
            try {
              const res = await fn(...args);
              if (res == null) {
                throw new Error("empty response from " + prop);
              }
              return res;
            } catch (error) {
              if (error.message === "login") {
                console.error("you need to re-authorize");
                state.user.auth = false;
              } else {
                throw error;
              }
            }
          };
        }
        return undefined;
      },
    }
  );

  const innerState = observable({
    index: -1,
    planHistory: [],
    plan: null,
    lastUpdateRequestTime: 0,
    planName: null,
    getPlanByIndex: (index) => {
      if (innerState.planHistory.length > index) {
        const planJSON = innerState.planHistory[index];
        // inject background
        if (planJSON && innerState.plan && innerState.plan.background) {
          const { src, width, height } = innerState.plan.background;
          planJSON.background = {
            src,
            width,
            height,
          };
        }

        const plan = planFactory(
          planJSON,
          settingsState,
          state.zoomState,
          state.intl
        );
        // inject coverage API
        injectCoverageAPI(plan, settingsState);
        // inject trenching API
        injectTrenchingAPI(plan);
        return plan;
      }

      return undefined;
    },
  });

  // inject reactions
  injectMarketingApi(state, intl);

  // inject reactions
  injectReactions(state);

  // inject dialog API
  injectDialogApi(state);

  // inject tooltip API
  injectTooltipApi(state);

  // inject export API
  injectExportApi(state);

  // inject history API into the store
  extendObservable(state, {
    /**
     * Use this method only in API
     */
    savePlan: throttle(
      action(async () => {
        if (innerState.plan != null) {
          // set Planning time
          const updateTime = performance.now();

          // planningTime in seconds
          state.plan.setPlanningTime(
            state.plan.planningTime +
              Math.round((updateTime - innerState.lastUpdateRequestTime) / 1000)
          );
          innerState.plan.version = planVersion;
          state.updatePlan();
          //temporary stats
          state.saveStats();

          innerState.lastUpdateRequestTime = updateTime;

          action(() => {
            const plan = innerState.plan.toJSON;
            // console.debug("History updated", plan);
            const { length } = innerState.planHistory;
            innerState.planHistory.splice(
              innerState.index + 1,
              length - innerState.index + 1,
              plan
            );
            innerState.index = innerState.planHistory.length - 1;
          })();
        }
      }),
      1000
    ),
    get plan() {
      return innerState.plan;
    },
    set plan(plan) {
      action(() => {
        innerState.planHistory.replace(plan ? [plan] : []);
        innerState.index = innerState.planHistory.length - 1;
        innerState.plan = innerState.getPlanByIndex(innerState.index);
      })();
    },
    get undo() {
      if (innerState.index > 0) {
        return async () => {
          action(() => {
            innerState.index--;
            innerState.plan = innerState.getPlanByIndex(innerState.index);
            state.updatePlan();
            state.toMaxPossibleStepIfNeeded();
            innerState.plan.cleanupRaster();
            state.canFetchPrice = true;
          })();

          innerState.plan.cleanupSprinklerCoverage();
          innerState.plan.cleanupSensorCoverage();
          innerState.plan.cleanupTrenches();

          state.plan.fillSprinklerCoverage();
          state.plan.fillSensorCoverage();
          state.plan.cleanupPipelineCircuits();
          state.plan.recalculatePipelineCircuits();
          state.plan.recalculateTrenches(
            state.hiddenPipeColors,
            state.zoomState.zoomDelta
          );
        };
      }

      return undefined;
    },
    get redo() {
      if (innerState.index < innerState.planHistory.length - 1) {
        return async () => {
          action(() => {
            innerState.index++;
            innerState.plan = innerState.getPlanByIndex(innerState.index);
            state.updatePlan();
            state.toMaxPossibleStepIfNeeded();
            innerState.plan.cleanupRaster();
            state.canFetchPrice = true;
          })();

          innerState.plan.cleanupSprinklerCoverage();
          innerState.plan.cleanupSensorCoverage();
          innerState.plan.cleanupTrenches();

          state.plan.fillSprinklerCoverage();
          state.plan.fillSensorCoverage();
          state.plan.cleanupPipelineCircuits();
          state.plan.recalculatePipelineCircuits();
          state.plan.recalculateTrenches(
            state.hiddenPipeColors,
            state.zoomState.zoomDelta
          );
        };
      }

      return undefined;
    },
    get planIsEditable() {
      return state.plan
        ? state.user && state.user.role === "admin"
          ? true
          : state.plan.editable
        : true;
    },
  });

  // inject user tools
  extendObservable(state, {
    selectedTool: null,
    setSelectedTool: action((tool = "select") => {
      state.selectedTool = tool;
      state.selectedOtherTool = null;
      state.clearSelectedElement();
      if (["polygon", "ruler", "scale", "pipeline-add"].indexOf(tool) >= 0) {
        state.setCursor("crosshair");
      } else {
        state.setCursor();
      }
    }),
    selectedOtherTool: null,
    setSelectedOtherTool: action((tool = null) => {
      if (
        state.selectedTool == null ||
        ["select", "ruler"].indexOf(state.selectedTool) >= 0 ||
        !(tool === "draggable" || state.selectedOtherTool === "draggable")
      ) {
        state.selectedTool = tool == null ? "select" : null;
      }
      state.selectedOtherTool = tool;
    }),
  });

  // inject user selection
  extendObservable(state, {
    selectedElementId: undefined,
    hoveredElementId: undefined,
    elementIsChanged: false,
    setSelectedElement(id) {
      if (state.selectedTool === "select") {
        state.selectedElementId = id;
        state.hoveredElementId = undefined;
      }
    },
    setHoveredElementId: async (id) => {
      if (
        state.selectedTool === "select" ||
        state.selectedTool === "pipeline-add"
      ) {
        state.hoveredElementId = id;
      }
    },
    setElementIsChanged: action((val) => {
      state.elementIsChanged = val;
    }),
    get selectedElement() {
      return state.plan && state.selectedElementId != null
        ? state.plan.elements.find((e) => e.id === state.selectedElementId)
        : undefined;
    },
    get hoveredElement() {
      return state.plan && state.hoveredElementId != null
        ? state.plan.elements.find((e) => e.id === state.hoveredElementId)
        : undefined;
    },
    clearSelectedElement: () => {
      if (state) state.selectedElementId = undefined;
    },
  });

  injectStepsAPI(state, settingsState, calcApi, restApi);

  // inject plan helper functions
  extendObservable(state, {
    get hasDev() {
      return hasDev;
    },
    onRemoveElement: action(
      async (id = state.selectedElementId, doShowConfirm = true) => {
        if (id == null || state.plan == null) return;

        try {
          const el = state.plan.findById(id);
          if (el == null) return;

          if (el && !el.disabled && state.planIsEditable) {
            if (doShowConfirm) {
              const confirmLabels = state.settingsState
                ? state.settingsState.dialog
                : null;

              await state.showConfirm({
                title: confirmLabels
                  ? state.intl.formatMessage({ id: confirmLabels.deleteTitle })
                  : "Confirm",
                description: confirmLabels
                  ? state.intl.formatMessage({ id: el.deleteConfirmText })
                  : "Confirm",
              });
            }
            action(() => {
              // if it is a connection point remove closest lines
              const removedIds = state.plan.removeElementById(
                ...(el.isConnectionPoint ? el.lines.map((l) => l.id) : [id])
              );

              // check if actual changes were made
              if (removedIds.length > 0) {
                state.reactions.onElementChange(el);
                if (removedIds.indexOf(state.plan.selectedPointId) >= 0) {
                  state.plan.selectedPointId = undefined;
                }
                if (removedIds.indexOf(state.selectedElementId) >= 0) {
                  state.selectedElementId = undefined;
                }
              }
            })();
          }
        } catch (e) {
          console.error(e);
        }
      }
    ),
    createNewElement: action((el) => {
      state.setSelectedTool("select");

      if (state.plan) {
        const element = state.plan.addElementToStorage(el);
        if (element) {
          state.selectedElementId = element.id;
          state.plan.onElementChange(element.type);
        }
        state.savePlan();
        return element;
      }

      return null;
    }),
    cloneElement: action((id) => {
      const element = state.plan.findById(id);
      if (state.plan && element) {
        let cloneJSON = element.toJSON;
        if (element.type === "sprinkler") {
          const p = adjustmentPoint(
            element,
            state.plan.areas,
            sizeInPixelByMeters(
              state.settingsState.adjustmentPointThreshold,
              state.plan.scale
            )
          );

          if (
            p?.point &&
            (p.point.x !== element.x || p.point.y !== element.y)
          ) {
            cloneJSON.x = p.point.x;
            cloneJSON.y = p.point.y;
          }
        }
        const clone = state.plan.addElementToStorage({
          ...cloneJSON,
          id: null,
        });

        return clone;
      }

      return null;
    }),
  });

  // inject zoom and positioning API
  extendObservable(state, {
    containerWidth: 0,
    containerHeight: 0,
    zoomState: zoomStateFactory({ uiState: state }),
    showGrid: true,
    offsetX: 0,
    offsetY: 0,
    setContainerSize: action((width, height) => {
      state.containerWidth = width;
      state.containerHeight = height;
    }),
    setOffset: action((x, y) => {
      state.offsetX = x; //x > -1 ? x : 0;
      state.offsetY = y; //y> -1 ? y : 0;
    }),
    setMove: action((x, y) => {
      state.setOffset(x, y);
    }),
    toCenter: action(() => {
      const { plan, stepIdx, stepIdxByName } = state;
      const { panTo } = state.zoomState;

      if (plan == null) return undefined;

      const pointsOfElements = [
        ...(stepIdx >= stepIdxByName("areas")
          ? plan.areas.reduce((acc, { extremePoints }) => {
              acc.push(...extremePoints);
              return acc;
            }, [])
          : []),
        ...(stepIdx >= stepIdxByName("sprinklers")
          ? [...plan.sprinklers, ...plan.rzws, ...plan.raisedBeds].reduce(
              (acc, { extremePoints }) => {
                acc.push(...extremePoints);
                return acc;
              },
              []
            )
          : []),
        ...(stepIdx >= stepIdxByName("system-elements")
          ? plan.systemElements.map((s) => ({ x: s.x, y: s.y }))
          : []),
        ...(stepIdx >= stepIdxByName("pipeline")
          ? plan.pipePoints.map((s) => ({ x: s.x, y: s.y }))
          : []),
        ...(stepIdx >= stepIdxByName("sensors")
          ? plan?.sensors.map((s) => ({ x: s.x, y: s.y }))
          : []),
      ];

      panTo(
        pointsOfElements.length > 0
          ? pointsOfElements
          : plan.background?.width && plan.background?.height
          ? [
              { x: 0, y: 0 },
              { x: plan.background.width, y: 0 },
              { x: plan.background.width, y: plan.background.height },
              { x: 0, y: plan.background.height },
            ]
          : [],
        100
      );
    }),
    get viewbox() {
      const size = state.zoomState.sizeByZoom;
      return {
        x: state.offsetX,
        y: state.offsetY,
        w: size.w,
        h: size.h,
      };
    },
    get offsets() {
      return {
        x: state.offsetX,
        y: state.offsetY,
      };
    },
  });

  // inject grid
  extendObservable(state, {
    changeShowGrid: action(() => {
      state.showGrid = !state.showGrid;
    }),
    /**
     * @param size = grid size
     * @param value = grid size in meter
     * @param weight = grid line weight
     * scale = (user line length / value in meters entered by user)
     * scale * count (in px) = distance (in m)
     */
    get grid() {
      if (state.plan && state.plan.scale) {
        return generateGrid(state.plan.scale, state.zoomState.zoomDelta);
      } else return null;
    },
  });

  // inject pipe layering API
  extendObservable(state, {
    hiddenPipeColors: [],
    togglePipeColor: action((color) => {
      if (state.hiddenPipeColors.indexOf(color) >= 0) {
        state.hiddenPipeColors = state.hiddenPipeColors.filter(
          (c) => c !== color
        );
      } else {
        state.hiddenPipeColors.push(color);
      }
      state.plan.cleanupTrenches();
      state.plan.recalculateTrenches(
        state.hiddenPipeColors,
        state.zoomState.zoomDelta
      );
    }),
    hideAllPipes: action((colors) => {
      state.hiddenPipeColors = [...colors];

      state.plan.cleanupTrenches();
      state.plan.recalculateTrenches(
        state.hiddenPipeColors,
        state.zoomState.zoomDelta
      );
    }),
    showAllPipes: action(() => {
      state.hiddenPipeColors = [];

      state.plan.cleanupTrenches();
      state.plan.recalculateTrenches(
        state.hiddenPipeColors,
        state.zoomState.zoomDelta
      );
    }),
  });

  // inject additional SETTINGS
  extendObservable(state, {
    cursor: null,
    setCursor: action((cursor = null) => {
      state.cursor = cursor;
    }),
    get intl() {
      return intl;
    },
    get settingsState() {
      return settingsState;
    },
    get precision() {
      return 1;
    },
  });

  extendObservable(state, {
    showRestError: (method, e, descr = null) => {
      const confirmLabels = state.settingsState.dialog;
      const errorMessage = e && e.message ? ", message: " + e.message : "";
      const data =
        "(" +
        method +
        ", plan: " +
        state.planId +
        errorMessage +
        ", utc: " +
        new Date().getTime() +
        ")";
      let description = state.intl.formatMessage({
        id: descr == null ? confirmLabels.shopServerWrongAnswer : descr,
      });
      description = description.replace("&method", data);

      return state.showAlert({
        title: state.intl.formatMessage({ id: confirmLabels.failedConnection }),
        description,
      });
    },
    showWaterVolumeError: debounce((val) => {
      const value = val !== "" ? val * 1000 : undefined;
      if (value == null) return;

      const confirmLabels = state.settingsState.dialog.waterVolumeError;
      const { min, max } = state.settingsState.waterVolumeWrongData;

      if (value < min || value > max) {
        return state.showAlert({
          title: state.intl.formatMessage({ id: confirmLabels.title }),
          description: state.intl.formatMessage({
            id: confirmLabels.wrongDataDescription,
          }),
        });
      }

      return null;
    }, 600),
    showCircuitCrowdedWarning: debounce(() => {
      const pipeline =
        state.selectedElement && state.selectedElement.pipelines
          ? state.selectedElement.pipelines[0]
          : undefined;

      const confirmLabels = state.settingsState.dialog;
      if (!pipeline) return null;

      const maxWaterVolume = state.settingsState.maxWaterVolumeList.find(
        (v) => v.type === state.plan.irrigationTubeType
      )?.value;

      if (pipeline.waterQuantity > maxWaterVolume) {
        return state.showAlert({
          title: state.intl.formatMessage({ id: confirmLabels.warningTitle }),
          description: state.intl
            .formatMessage({ id: confirmLabels.circuitCrowdedDescription })
            .replace(
              "{maxWaterVolume}",
              state.intl.formatNumber(maxWaterVolume / 1000)
            ),
        });
      }

      return null;
    }, 600),
  });

  // inject Rest API
  const generateCardPayload = (bomItems) => {
    const products = convertBomListByBoxes(state.settingsState, bomItems);

    let card = {};
    for (const product of products) {
      const { articleId, articleNO, quantity } = bomItemFactory(
        product,
        state.plan.pricesDictionary
      );
      if (quantity === 0) continue;

      if (card[articleId] == null) {
        card[articleId] = {
          article_id: articleId,
          article_no: articleNO,
          quantity: quantity,
        };
      } else {
        card[articleId].quantity += quantity;
      }
    }

    return Object.values(card);
  };

  extendObservable(state, {
    get planName() {
      if (innerState.planName != null) {
        return innerState.planName;
      }

      const date = new Date();

      const labels = settingsState.texts;
      const planName =
        labels && labels.planName
          ? intl.formatMessage({ id: labels.planName })
          : "Plan_{date}_{time}";

      return planName
        .replace("{date}", formatDate(intl, date))
        .replace("{time}", intl.formatTime(date), {
          hour12: false,
        });
    },
    set planName(name) {
      innerState.planName = name;
    },
    setPlanName: action((val) => {
      state.planName = val;
    }),
    planId: null,
    get user() {
      return userStateFactory(restApi);
    },
    canFetchPrice: true,
    fetchPrices: async () => {
      const { pricesDictionary } = state.plan;

      const articles = Object.keys(getAllArticles(settingsState)).map(
        (article_no) => ({
          article_no,
        })
      );

      if (
        !state.planIsEditable ||
        !state.canFetchPrice ||
        articles.length === 0
      )
        return false;

      action(() => {
        state.calculatingTitle =
          state.settingsState.texts.steps.bom.getPricesCalculationTitle;
      })();
      await sleep(0);

      try {
        // get prices by articleNO
        const prices = await restApi.getPricesByArticleNumbers(articles);
        if (!prices) return false;

        action(() => {
          prices.forEach(({ article_no, net_price, vat, article_id }) => {
            const articleNO = normalizeArticleNO(article_no);

            let price = +net_price;
            // price without VAT
            price += (price * vat) / 100;

            pricesDictionary.set(articleNO, {
              articleId: article_id,
              price,
            });
          });

          state.canFetchPrice = false;
          state.calculatingTitle = null;
        })();
      } catch (e) {
        console.error(e);
        state.showRestError("getPrices", e);
      } finally {
        action(() => {
          state.calculatingTitle = null;
        })();
      }

      return true;
    },
    generateCartURL: async (bomItems) => {
      if (!state.plan) throw new Error("Plan not set");

      action(() => {
        state.calculatingTitle =
          state.settingsState.texts.steps.bom.cartCalculationTitle;
      })();
      await sleep(0);

      try {
        const cartURL = await restApi.getCartUrl(
          state.planId,
          generateCardPayload(bomItems)
        );

        if (cartURL && !state.hasDev) {
          return cartURL;
        }
      } catch (e) {
        console.error(e);
        state.showRestError("createCart", e);
        throw e;
      } finally {
        action(() => {
          state.calculatingTitle = null;
        })();
      }
    },
    generateWishListURL: async () => {
      if (!state.plan) throw new Error("Plan not set");
      action(() => {
        state.calculatingTitle =
          state.settingsState.texts.steps.bom.wishListCalculationTitle;
      })();
      await sleep(0);

      try {
        const wishListURL = await restApi.getWishListUrl(
          state.planId,
          generateCardPayload(state.plan.bomItems)
        );

        if (wishListURL && !state.hasDev) {
          return urlDecorator(wishListURL);
        }
      } catch (e) {
        console.error(e);
        state.showRestError("wishListURL", e);
        throw e;
      } finally {
        action(() => {
          state.calculatingTitle = null;
        })();
      }
    },
    duplicatePlan: async () => {
      action(() => {
        state.calculatingTitle =
          "texts.steps.bom.popup.duplication.duplicatingPlan";
      })();
      await sleep(0);

      try {
        const result = await restApi.duplicatePlan(state.planId);
        action(() => {
          state.calculatingTitle = null;
        })();

        if (result.plan_id) {
          const win = window.open(`?planId=${result.plan_id}`, "_blank");
          win.focus();
        }

        await state.showAlert({
          title: state.intl.formatMessage({
            id: "texts.steps.bom.popup.duplication.success.title",
          }),
          description: state.intl.formatMessage({
            id: "texts.steps.bom.popup.duplication.success.message",
          }),
        });
      } catch (e) {
        action(() => {
          state.calculatingTitle = null;
        })();
        console.error("Error in duplicatePlan", e);
        state.showRestError("duplicatePlan", e);
      }
    },
    get userIsAdmin() {
      return state.user && state.user.role === "admin";
    },
  });

  injectPlanSaveApi(state, restApi, calcApi);

  // inject precipitation config popup
  if (hasDev) {
    extendObservable(state, {
      showPrecipitationConfigPopup: false,
      togglePrecipitationConfigPopup: () => {
        state.showPrecipitationConfigPopup =
          !state.showPrecipitationConfigPopup;
      },
      saveSettings: async () => {
        const { mpRotator, mpStrip } = settingsState.elements;

        // collect rotator and strip sprinklers
        const settings = {
          mpRotator: {
            types: mpRotator.map(({ name, precipitation }) => ({
              name,
              precipitation,
            })),
          },
          mpStrip: {
            types: mpStrip.map(({ name, precipitation }) => ({
              name,
              precipitation,
            })),
          },
        };
        action(() => {
          state.calculatingTitle = "Saving settings";
        })();
        try {
          await calcApi.updateSettings(settings);
        } catch (e) {
          console.error("Save settings failed");
        } finally {
          action(() => {
            state.calculatingTitle = null;
          })();
        }
      },
    });
  }

  reaction(
    () => state.canFetchPrice,
    (canFetchPrice) => {
      if (canFetchPrice === false) {
        state.sendMarketingStatistics();
      }
    }
  );

  return state;
};

export default uiStateFactory;
